import { getWindow } from '@squareup/dex-utils-environment';

class FetchCacheManager {
  private cache: Cache | undefined;
  private cacheVersion: string;

  constructor(cacheVersion: string) {
    this.cacheVersion = cacheVersion;
  }

  /**
   * For a given url, return back a response if it exists in cache
   * @param url The url to store
   * @returns A Response object if it exists, otherwise undefined
   */
  async findResponse(url: string): Promise<Response | undefined> {
    await this.setupCache();
    if (!this.cache) {
      return undefined;
    }

    const response = await this.cache.match(url);

    // Ensure it's a valid response. In general, invalid
    // responses shouldn't end up in cache
    if (!response?.ok) {
      return undefined;
    }

    return response;
  }

  /**
   * Stores a successful response in cache given a url.
   * If the cache is not available, simply no-op
   * If the resposne is unsuccessful, throw an error
   * @param url The url under which we cache
   * @param response The response which we are caching. Unsuccessful responses will throw
   */
  async storeResponse(url: string, response: Response): Promise<void> {
    await this.setupCache();
    if (!this.cache) {
      return;
    }

    // Never allow storing unsuccessful responses
    if (!response.ok) {
      throw new Error(
        `Attempted to store a response for url ${url} whose status was ${response.status}`
      );
    }

    await this.cache.put(url, response.clone());
  }

  /**
   * Clears the cache entry for the given url
   * @param url The url for which entry we're clearing
   */
  async deleteCacheEntry(url: string): Promise<void> {
    await this.setupCache();
    if (!this.cache) {
      return;
    }

    await this.cache.delete(url);
  }

  /**
   * We will lazily setup the cache for the caller,
   * since we can't initialize it in the constructor
   * @returns A promise to await
   */
  private async setupCache(): Promise<void> {
    const cacheStorage = this.getCacheStorage();

    if (!cacheStorage || this.cache) {
      return;
    }

    try {
      this.cache = await cacheStorage.open(this.cacheVersion);
    } catch {
      // Swallow this error as an indication that the cache isn't available
    }
  }

  private getCacheStorage(): CacheStorage | undefined {
    const win = getWindow();
    return win && win.caches;
  }
}

export { FetchCacheManager };
