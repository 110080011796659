import { AsProp, NullableClassName } from '@squareup/dex-types-shared-ui';
import { TestProps } from '@squareup/dex-types-shared-utils';
import clsx, { ClassValue } from 'clsx';
import React, {
  PropsWithRef,
  ReactElement,
  ReactNode,
  Ref,
  FunctionComponent,
} from 'react';

import { DynamicTag } from '../DynamicTag';

import styles from './box.module.css';
import { createClassName, Spacing } from './spacing-helpers';

type BorderSpacing = 'standard' | '10' | '20';
type BorderRadius = 'standard' | '0' | '4' | '6' | '8' | '12';

interface BorderProp {
  line?:
    | BorderSpacing
    | {
        left?: BorderSpacing;
        right?: BorderSpacing;
        top?: BorderSpacing;
        bottom?: BorderSpacing;
      }
    | undefined;
  radius?:
    | BorderRadius
    | {
        topLeft?: BorderRadius;
        topRight?: BorderRadius;
        bottomLeft?: BorderRadius;
        bottomRight?: BorderRadius;
      }
    | undefined;
}

interface InternalBoxProps {
  children?: ReactNode;
  margin?:
    | {
        left?: Spacing | undefined;
        right?: Spacing | undefined;
        top?: Spacing | undefined;
        bottom?: Spacing | undefined;
        horizontal?: Spacing | undefined;
        vertical?: Spacing | undefined;
      }
    | undefined;
  padding?:
    | {
        left?: Spacing | undefined;
        right?: Spacing | undefined;
        top?: Spacing | undefined;
        bottom?: Spacing | undefined;
        horizontal?: Spacing | undefined;
        vertical?: Spacing | undefined;
      }
    | undefined;
  border?: BorderSpacing | BorderProp | undefined;
  shadow?: '10' | '20' | '30' | undefined;
}

type AllBoxProps = InternalBoxProps & AsProp & TestProps;
type BoxProps<
  E = HTMLElement,
  T extends React.HTMLAttributes<E> = React.HTMLAttributes<E>
> = PropsWithRef<
  AllBoxProps &
    T &
    Omit<React.DOMAttributes<E>, 'color'> & { ref?: Ref<E> | undefined }
>;

const MARGIN_CLASS_PREFIX = 'm';
const PADDING_CLASS_PREFIX = 'p';
const BORDER_CLASS_PREFIX = 'b';

const buildSpacingClassValues = (
  prefix: string,
  left: Spacing | undefined,
  right: Spacing | undefined,
  top: Spacing | undefined,
  bottom: Spacing | undefined,
  horizontal: Spacing | undefined,
  vertical: Spacing | undefined
): ClassValue[] => {
  return [
    [left, 'l'],
    [right, 'r'],
    [top, 't'],
    [bottom, 'b'],
    [horizontal, 'l'],
    [horizontal, 'r'],
    [vertical, 't'],
    [vertical, 'b'],
  ]
    .filter(([spacing, _]) => Boolean(spacing))
    .map(([spacing, direction]) => {
      return styles[
        createClassName(
          prefix,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          direction!,
          spacing as Spacing
        ) as keyof typeof styles
      ];
    }) as ClassValue[];
};

const borderRadiusToClassName = (
  value: BorderRadius,
  identifier?: string
): string => {
  const identifierValue = identifier ? `${identifier}-` : '';
  if (value === 'standard') {
    return `${BORDER_CLASS_PREFIX}-${identifierValue}r-6`;
  } else {
    return `${BORDER_CLASS_PREFIX}-${identifierValue}r-${value}`;
  }
};

const buildBorderClassValues = (
  border: BorderSpacing | BorderProp | undefined
) => {
  if (!border) {
    return [];
  }

  let results: string[] = [];
  if (border === 'standard') {
    results = [`${BORDER_CLASS_PREFIX}-l-std`, `${BORDER_CLASS_PREFIX}-r-6`];
  }

  if (typeof border !== 'string' && border.line) {
    if (border.line === 'standard') {
      results = [...results, `${BORDER_CLASS_PREFIX}-l-std`];
    } else if (typeof border.line === 'string') {
      results = [...results, `${BORDER_CLASS_PREFIX}-l-${border.line}`];
    } else {
      const lines = [
        [border.line.top, 't'],
        [border.line.bottom, 'b'],
        [border.line.left, 'l'],
        [border.line.right, 'r'],
      ]
        .filter(([prop, _]) => Boolean(prop))
        .map(([prop, identifier]) => {
          const value = prop === 'standard' ? 'std' : prop;
          return `${BORDER_CLASS_PREFIX}-${identifier}-l-${value}`;
        });
      results = [...results, ...lines];
    }
  }

  if (typeof border !== 'string' && border.radius) {
    if (typeof border.radius === 'string') {
      results = [...results, borderRadiusToClassName(border.radius)];
    } else {
      const radii = [
        [border.radius.topLeft, 'tl'],
        [border.radius.topRight, 'tr'],
        [border.radius.bottomLeft, 'bl'],
        [border.radius.bottomRight, 'br'],
      ]
        .filter(([prop, _]) => Boolean(prop))
        .map(([radius, identifier]) => {
          return borderRadiusToClassName(radius as BorderRadius, identifier);
        });
      results = [...results, ...radii];
    }
  }

  return results.map((className) => {
    return styles[className as keyof typeof styles];
  });
};

type FunctionComponentWithBoxProps<
  E = HTMLElement,
  T extends React.HTMLAttributes<E> = React.HTMLAttributes<E>
> = FunctionComponent<BoxProps<E, T>>;

const Box: FunctionComponentWithBoxProps = React.forwardRef<
  HTMLElement,
  AllBoxProps & NullableClassName
>(
  (
    { as, children, margin, padding, border, className, shadow, ...rest },
    ref
  ) => {
    const marginClasses = buildSpacingClassValues(
      MARGIN_CLASS_PREFIX,
      margin?.left,
      margin?.right,
      margin?.top,
      margin?.bottom,
      margin?.horizontal,
      margin?.vertical
    );
    const paddingClasses = buildSpacingClassValues(
      PADDING_CLASS_PREFIX,
      padding?.left,
      padding?.right,
      padding?.top,
      padding?.bottom,
      padding?.horizontal,
      padding?.vertical
    );

    const borderClasses = buildBorderClassValues(border);

    return (
      <DynamicTag
        {...rest}
        ref={ref}
        defaultTag="div"
        as={as}
        className={clsx(
          ...marginClasses,
          ...paddingClasses,
          ...borderClasses,
          shadow && styles[`sh-${shadow}` as keyof typeof styles],
          className
        )}
      >
        {children}
      </DynamicTag>
    );
  }
);

Box.displayName = 'Box';

// Unfortunately, to ensure users can cast this component, we must use a typecast
// with a function along with generics. You cannot pass along the generic
// with `type` keywords as an invocation.
const exportedBox = Box as <
  E = HTMLElement,
  // eslint-disable-next-line no-use-before-define
  T extends React.HTMLAttributes<E> = React.HTMLAttributes<E>
>(
  // eslint-disable-next-line no-use-before-define
  props: BoxProps<E, T>
) => ReactElement;

export { exportedBox as Box, type BoxProps, type InternalBoxProps };
