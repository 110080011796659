/**
 * Partitions the given array into two sets and returns it in a tuple.
 * The first set is the match and second the non matched.
 */
function partitionMatched<T, TMatched extends T, TNonMatched extends T>(
  array: T[],
  callback: (item: T) => boolean
): [TMatched[], TNonMatched[]] {
  const matches: TMatched[] = [];
  const nonMatches: TNonMatched[] = [];

  array.forEach((item) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (callback(item) ? matches : nonMatches).push(item as any)
  );

  return [matches, nonMatches];
}

export { partitionMatched };
