enum ViewEvent {
  PageLoad = 'Page Load - DL',
}

enum ActionEvent {
  AppCreation = 'Developer App Creation',
  NoninitialAppCreation = 'Noninitial App Creation',
}

enum TrackingAction {
  AppCreation = 'create-app',
}

enum TrackingVariant {
  developers = 'developers',
}

interface TrackingViewProps {
  event: ViewEvent;
}

interface TrackingActionProps {
  event: ActionEvent;
  action: TrackingAction;
}

type TrackingProps =
  | TrackingViewProps
  | (TrackingActionProps & { variant: TrackingVariant });

export {
  ActionEvent,
  TrackingAction,
  TrackingVariant,
  type TrackingProps,
  ViewEvent,
};
