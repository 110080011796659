import { createContext } from 'react';

interface IToasterContext {
  getToaster(): globalThis.HTMLMarketToasterElement | null;
}

const uninitializedValue = {
  getToaster: () => null,
};

export const ToasterContext =
  createContext<IToasterContext>(uninitializedValue);
