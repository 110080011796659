import { AsProp, NullableClassName } from '@squareup/dex-types-shared-ui';
import { TrackedProps } from '@squareup/dex-types-shared-utils';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

import { Box, BoxProps } from '../Box';

import styles from './text.module.css';

type TextWeight = 'semi-bold' | 'medium';

interface TextPropsInner {
  children: ReactNode;
  weight?: TextWeight | undefined;
}

type ColorVariants = {
  colorVariant?:
    | '10'
    | '20'
    | '30'
    | 'inverse'
    | 'black'
    | 'white'
    | 'currentColor'
    | undefined;
};

type TextProps<E> = Omit<
  TextPropsInner &
    ColorVariants &
    NullableClassName &
    AsProp &
    BoxProps<E> &
    Partial<TrackedProps>,
  'color'
>;

type TextVariants = { variant: '10' | '20' | '30' };

const Paragraph = React.forwardRef<
  HTMLElement,
  TextProps<HTMLParagraphElement> & TextVariants
>(
  (
    { children, weight, className, as, variant, colorVariant, slot, ...rest },
    ref
  ) => {
    return (
      <Box
        {...rest}
        ref={ref}
        as={as || 'p'}
        className={clsx(
          styles['base'],
          styles[`paragraph-${variant}`],
          weight && styles[`${weight}-${variant}` as keyof typeof styles],
          colorVariant &&
            styles[`color-${colorVariant}` as keyof typeof styles],
          className
        )}
        slot={slot}
      >
        {children}
      </Box>
    );
  }
);

Paragraph.displayName = 'Paragraph';

const Paragraph30 = React.forwardRef<
  HTMLElement,
  TextProps<HTMLParagraphElement>
>((props, ref) => {
  return <Paragraph {...props} ref={ref} variant={'30'}></Paragraph>;
});

Paragraph30.displayName = 'Paragraph30';

const Paragraph20 = React.forwardRef<
  HTMLElement,
  TextProps<HTMLParagraphElement>
>((props, ref) => {
  return <Paragraph {...props} ref={ref} variant={'20'}></Paragraph>;
});

Paragraph20.displayName = 'Paragraph20';

const Paragraph10 = React.forwardRef<
  HTMLElement,
  TextProps<HTMLParagraphElement>
>((props, ref) => {
  return <Paragraph {...props} ref={ref} variant={'10'}></Paragraph>;
});

Paragraph10.displayName = 'Paragraph10';

const CodeField = React.forwardRef<
  HTMLElement,
  TextProps<HTMLParagraphElement>
>((props, ref) => {
  const { className, ...rest } = props;
  return (
    <Paragraph
      {...rest}
      ref={ref}
      variant={'20'}
      className={clsx(styles['code-field'], className)}
    ></Paragraph>
  );
});

CodeField.displayName = 'CodeField';

export {
  Paragraph30,
  Paragraph20,
  Paragraph10,
  Paragraph,
  CodeField,
  type TextProps,
  type ColorVariants,
  type TextVariants,
  type TextWeight,
};
