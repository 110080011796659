import { defineCustomElements } from '@market/web-components/loader';
import { publishUnhandledError } from '@squareup/dex-utils-application-behavior-events';
import { getWindow } from '@squareup/dex-utils-environment';

const UIProvider = () => {
  // Load up the UI component definitions onto the window
  try {
    const win = getWindow();
    win ? defineCustomElements(win) : defineCustomElements();
  } catch (error) {
    publishUnhandledError(error as Error);
  }

  return null;
};

export { UIProvider };
