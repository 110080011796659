import { TestProps } from '@squareup/dex-types-shared-utils';
import { Box, Paragraph20 } from '@squareup/dex-ui-shared-base';
import { MarketDropdown, MarketPopover } from '@squareup/dex-ui-shared-market';
import { setLocalAndForwardedRef } from '@squareup/dex-utils-dom';
import clsx from 'clsx';
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import styles from './popover.module.css';

interface PopoverProps<
  T extends globalThis.HTMLElement = globalThis.HTMLElement
> {
  target: ReactNode;
  onMouseEnter?: (target: T) => void;
  onMouseLeave?: (target: T) => void;
  onFocus?: (target: T) => void;
  onBlur?: (target: T) => void;
  // Disable the automatic interactions for the popover
  disableInteractions?: boolean | undefined;
  interaction?: 'hover' | 'click' | 'persistent' | undefined;
}

/**
 * General popover component that acts as a customizable tooltip.
 * You can control when it appears, and what the content looks like,
 * without being trapped into the MarketTooltip styling
 */
const Popover = React.forwardRef<
  globalThis.HTMLMarketDropdownElement,
  PropsWithChildren<PopoverProps & TestProps>
>(
  (
    {
      children,
      target,
      onMouseEnter,
      onMouseLeave,
      onFocus,
      onBlur,
      disableInteractions = false,
      interaction = 'hover',
      testId,
    },
    ref
  ) => {
    const [isServer, setIsServer] = useState(true);
    const [isMarketComponentLoaded, setIsMarketComponentLoaded] =
      useState(false);
    const marketDropdownRef =
      useRef<globalThis.HTMLMarketDropdownElement | null>(null);

    useEffect(() => {
      setIsServer(false);
    }, []);

    const onMarketRef = useCallback(
      (node: globalThis.HTMLMarketDropdownElement) => {
        setLocalAndForwardedRef(marketDropdownRef, ref)(node);

        // Unfortunately, in an SSR world, we simply don't know when the
        // market component will be ready. So we'll use a 2 phased approach
        // 1. If not on the server, never show the market component. The reason for this
        //    is because the slotted content won't appear, and nextjs will complain about hydration errors.
        //    You'll also see flashes of content
        // 2. If on the client, don't show the market-component UNTIL it is hydrated. Web components are weird.
        //    They take time to hydrate, outside of the React render cycle. So we'll just poll for it until
        //    it's hydrated, and then finally show the tooltip
        function waitForWebComponentHydration() {
          if (node.hasAttribute('hydrated')) {
            requestAnimationFrame(() => {
              setIsMarketComponentLoaded(true);
            });
          } else {
            setTimeout(() => {
              waitForWebComponentHydration();
            }, 10);
          }
        }

        if (node !== null) {
          waitForWebComponentHydration();
        }
      },
      [ref]
    );

    // The target should be the a tag, since the trigger element we made is not interactable
    const onMouseEnterWrapper = useCallback(
      (e: React.MouseEvent<globalThis.HTMLElement>) => {
        onMouseEnter?.(e.target as HTMLElement);
      },
      [onMouseEnter]
    );

    const onMouseLeaveWrapper = useCallback(
      (e: React.MouseEvent<globalThis.HTMLElement>) => {
        onMouseLeave?.(e.target as HTMLElement);
      },
      [onMouseLeave]
    );

    const onFocusWrapper = useCallback(
      (e: React.FocusEvent<globalThis.HTMLElement>) => {
        onFocus?.(e.target as HTMLElement);
      },
      [onFocus]
    );

    const onBlurWrapper = useCallback(
      (e: React.FocusEvent<globalThis.HTMLElement>) => {
        onBlur?.(e.target as HTMLElement);
      },
      [onBlur]
    );

    if (!target) {
      return null;
    }

    // Add extra props to the passed in target
    const trigger = (
      <Box
        as="span"
        slot="trigger"
        onMouseEnter={onMouseEnterWrapper}
        onMouseLeave={onMouseLeaveWrapper}
        onFocus={onFocusWrapper}
        onBlur={onBlurWrapper}
      >
        {target}
      </Box>
    );

    return (
      <>
        {!isMarketComponentLoaded && trigger}
        {!isServer && (
          <Box
            as="span"
            className={clsx(
              styles.tooltip,
              !isMarketComponentLoaded && styles.hidden
            )}
          >
            <MarketDropdown
              className={clsx(styles.dropdown)}
              data-testid={testId}
              interaction={interaction}
              popoverPlacement="top"
              popoverDistance={8}
              ref={onMarketRef}
              {...(disableInteractions && { disabled: disableInteractions })}
            >
              {trigger}
              <MarketPopover
                slot="popover"
                className={styles['popover-content']}
              >
                <Paragraph20
                  as="span"
                  className={styles['inverse-color']}
                  testId="popover-content"
                >
                  {children}
                </Paragraph20>
              </MarketPopover>
            </MarketDropdown>
          </Box>
        )}
      </>
    );
  }
);

Popover.displayName = 'Popover';

export { Popover };
