// App router doesnt support the object format anymore.
// Error: Dynamic href `/apps/[app_id]` found in <Link> while using the `/app` router, this is not supported.
// https://nextjs.org/docs/messages/app-dir-dynamic-href

import { ParsedUrlQuery } from 'querystring';

/**
 * Serializes the given query into the pathname fo dynamic format.
 * `/apps/[app_id]` -> `/apps/1`
 */
const urlObjectToString = ({
  pathname,
  query,
}: {
  pathname: string;
  query: ParsedUrlQuery;
}): string => {
  let result = pathname;
  for (const key in query) {
    if (Object.prototype.hasOwnProperty.call(query, key)) {
      const value = query[key];
      result = result.replace(`[${key}]`, value as string);
    }
  }

  return result;
};

/**
 * Converts a ParsedUrlQuery to an object key value pair.
 * For some dumb reason the next team supports the following idiotic legacy behavior.
 * This addresses that. If there are two keys with the same value in the url the
 * last one wins.
 *
 * For example, the query string `'foo=bar&#x26;abc=xyz&#x26;abc=123'` is parsed into:
 *
 * ```js
 * {
 *   foo: 'bar',
 *   abc: ['xyz', '123']
 * }
 * ```
 *
 */
const parsedUrlQueryToObject = (query: ParsedUrlQuery) => {
  const result: { [key: string]: string | undefined } = {};
  for (const key in query) {
    const current = query[key];
    result[key] =
      typeof current === 'string' || current === undefined
        ? current
        : current[0];
  }

  return result;
};

export { redirect } from 'next/navigation';
export { urlObjectToString, parsedUrlQueryToObject };
