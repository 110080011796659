type Spacing =
  | '0.25x'
  | '0.5x'
  | '0.75x'
  | '1x'
  | '1.5x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x';

const createClassName = (
  prefix: string,
  direction: string,
  spacing: Spacing
) => {
  const spacingValue =
    spacing[1] === '.' ? `${spacing[0]}${spacing.slice(2)}` : spacing;
  return `${prefix}-${direction}-${spacingValue}`;
};

export { type Spacing, createClassName };
