import {
  ThunkAction,
  Selector,
  AnyAction,
  EnhancedStore,
} from '@reduxjs/toolkit';
import { ThunkMiddlewareFor } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { docsShellViewStateStoreConfig } from '@squareup/dex-data-dex-tech-docs-view-state-slices';
import { getDeveloperApiStoreConfig } from '@squareup/dex-data-shared-developer-api';
import { combineReducers } from 'redux';

const developerApiStoreConfig = getDeveloperApiStoreConfig();
const reducers = {
  [docsShellViewStateStoreConfig.reducerPath]:
    docsShellViewStateStoreConfig.reducer,
  [developerApiStoreConfig.reducerPath]: developerApiStoreConfig.reducer,
};

const combinedReducers = combineReducers(reducers);
const middleware = developerApiStoreConfig.middleware;

type DocsStoreState = ReturnType<typeof combinedReducers>;
type Middlewares = [ThunkMiddlewareFor<DocsStoreState>];
type DocsStore = EnhancedStore<DocsStoreState, AnyAction, Middlewares>;
type DocsStoreDispatch = DocsStore['dispatch'];
type DocsStoreSelector<T> = Selector<DocsStoreState, T>;

type DocsStoreAction<T> = ThunkAction<
  T,
  DocsStoreState,
  undefined /* Extra args undefined for now*/,
  AnyAction
>;

type DocsStoreAsyncAction<T> = ThunkAction<
  Promise<T>,
  DocsStoreState,
  undefined /* Extra args undefined for now*/,
  AnyAction
>;

export {
  reducers,
  middleware,
  type DocsStoreSelector,
  type DocsStore,
  type DocsStoreDispatch,
  type DocsStoreAction,
  type DocsStoreAsyncAction,
  type DocsStoreState,
};
