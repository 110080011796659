import { UrlObject } from 'url';

import NextLink from 'next/link';
import { ComponentProps } from 'react';

import { LinkContextSearchParamPersistor } from './LinkContext';

function isStringUrlRelative(href: string): boolean {
  if (
    href.startsWith('http:') ||
    href.startsWith('https:') ||
    href.startsWith('/') ||
    href.startsWith('#')
  ) {
    return false;
  }

  return true;
}

function isRelativeUrl(href?: string | UrlObject | undefined): boolean {
  if (!href) {
    return false;
  }

  if (typeof href === 'string') {
    try {
      // A parseable URL is not relative. Therefore, if it throws,
      // we need to do additional checks
      new URL(href);
      return false;
    } catch {
      // pass
    }

    return isStringUrlRelative(href);
  }

  return isStringUrlRelative(
    `${href.protocol}${href.hostname}${href.pathname}${href.hash}`
  );
}

function persistSearchParams(
  href: ComponentProps<typeof NextLink>['href'] | undefined,
  persistedSearchParams: LinkContextSearchParamPersistor[],
  currentSearchParams: Record<string, string | undefined>
): ComponentProps<typeof NextLink>['href'] | undefined {
  if (!href) {
    return href;
  }

  if (persistedSearchParams.length === 0) {
    return href;
  }

  if (Object.entries(currentSearchParams).length === 0) {
    return href;
  }

  function convertQsps(
    hrefValue: string,
    qspString?: string | undefined
  ): string {
    const urlSearchParams = new URLSearchParams(qspString);
    for (const persistedParam of persistedSearchParams) {
      const currentValue = currentSearchParams[persistedParam.param];
      if (
        currentValue &&
        persistedParam.shouldUpdateHref(hrefValue) &&
        !urlSearchParams.has(persistedParam.param)
      ) {
        urlSearchParams.append(persistedParam.param, currentValue);
      }
    }

    return [...urlSearchParams.keys()].length > 0
      ? `?${urlSearchParams.toString()}`
      : '';
  }

  if (typeof href === 'string') {
    if (href.startsWith('#')) {
      // Ignore anchors
      return href;
    }

    const [start, end] = href.split('?');
    let hrefStart = start;

    // The hash might exist somewhere
    const partWithHash = end || start;

    // Hash should always be AFTER the query params
    const results = partWithHash?.split('#');
    const hash = results?.[1] ? `#${results[1]}` : '';
    let qspString = results?.[0];

    // If there was no end piece when splitting by ?, the start must be in the hash split
    if (!end) {
      hrefStart = results?.[0];
      qspString = '';
    }

    return `${hrefStart}${convertQsps(href, qspString || '')}${hash}`;
  } else {
    // eslint-disable-next-line no-param-reassign
    href.search = convertQsps(href.pathname || '', href.search || undefined);
    return href;
  }
}

export { persistSearchParams, isRelativeUrl };
