type SpecificationGrammar = 'open-api-3' | 'open-api-3-alpha';
type ExamplesGrammar = 'examples' | 'examples-alpha';

const DEFAULT_SPEC_GRAMMAR = 'open-api-3';

interface ContentVersionInfo {
  namespace: string | undefined;
  version?: string | undefined;
  isUnpublished: boolean;
  grammar: SpecificationGrammar | ExamplesGrammar;
}

interface SpecificationVersionInfo extends ContentVersionInfo {
  grammar: SpecificationGrammar;
}

interface ExamplesVersionInfo extends ContentVersionInfo {
  grammar: ExamplesGrammar;
}

export {
  DEFAULT_SPEC_GRAMMAR,
  type SpecificationGrammar,
  type ExamplesGrammar,
  type ContentVersionInfo,
  type SpecificationVersionInfo,
  type ExamplesVersionInfo,
};
