const rootDomains: Record<globalThis.EnvironmentName, string> = Object.freeze({
  production: 'squareup.com',
  staging: 'squareupstaging.com',
  development: 'localhost',
});

/**
 * If the provided url is a local host url and there is a default local host port defined the port is added
 */
function replaceLocalHostPort(url: string | undefined): string | undefined {
  const devUrl = `//${rootDomains.development}`;
  return url && getPublicLocalPort()
    ? url.replace(devUrl, `${devUrl}:${getPublicLocalPort()}`)
    : url;
}

/**
 * Takes the current URL, and transforms it to the correct environment
 * url. Note that we assume ALL urls coming in are in the production form,
 * so they are keying of of `squareup.com` or `.squareup.com` to determine urls to change
 * @param url The url to convert
 * @returns A transformed URL depending on the environment
 */
function convertToEnvironmentUrl(
  url: string,
  environment: globalThis.EnvironmentName
): string {
  // Since we assume urls are in production, we will skip this if on prod
  if (environment === 'production') {
    return url;
  }

  const envUrl = rootDomains[environment || 'production'];

  try {
    const urlObj = new URL(url);
    if (urlObj.hostname === envUrl) {
      return url;
    } else if (
      urlObj.hostname !== 'squareup.com' &&
      !urlObj.hostname.includes('.squareup.com')
    ) {
      // URL is not one to convert
      return url;
    } else {
      // Take care of the subdomains (developer.squareup.com)
      const subdomains = urlObj.hostname.split('.').slice(0, -2);

      if (environment !== 'development') {
        urlObj.hostname = `${subdomains.join('.')}${
          subdomains.length > 0 ? '.' : ''
        }${envUrl}`;
      } else {
        urlObj.hostname = envUrl;
        urlObj.protocol = 'http://';
        if (!urlObj.port) {
          urlObj.port = getPublicLocalPort() || '';
        }
      }

      return urlObj.toString();
    }
  } catch {
    return url;
  }
}

function throwIfUndefined(
  value: string | undefined,
  valueName: string
): string {
  if (!value) {
    throw new Error(`Value ${valueName} undefined. This variable is required.`);
  }

  return value as string;
}

/** **********************************************************
 * Wrappers around env variable urls
 ** **********************************************************/

function getPublicLocalPort(): string | undefined {
  return process.env.NEXT_PUBLIC_LOCAL_PORT;
}

function getNextPublicBaseURL(): string {
  return throwIfUndefined(
    replaceLocalHostPort(process.env.NEXT_PUBLIC_BASE_URL),
    'NEXT_PUBLIC_BASE_URL'
  );
}

function getNextPublicConnectURL(): string {
  return throwIfUndefined(
    replaceLocalHostPort(process.env.NEXT_PUBLIC_CONNECT_URL),
    'NEXT_PUBLIC_CONNECT_URL'
  );
}

function getNextPublicExplorerGatewayURL(): string {
  return throwIfUndefined(
    replaceLocalHostPort(process.env.NEXT_PUBLIC_EXPLORER_GATEWAY_URL),
    'NEXT_PUBLIC_EXPLORER_GATEWAY_URL'
  );
}

function getNextPublicSquareURL(): string {
  return throwIfUndefined(
    replaceLocalHostPort(process.env.NEXT_PUBLIC_SQUARE_URL),
    'NEXT_PUBLIC_SQUARE_URL'
  );
}

function getNextPublicSquareAppURL(): string {
  return throwIfUndefined(
    replaceLocalHostPort(process.env.NEXT_PUBLIC_SQUARE_APP_URL),
    'NEXT_PUBLIC_SQUARE_APP_URL'
  );
}

function getNextPublicSquareDocsURL(): string {
  return throwIfUndefined(
    replaceLocalHostPort(process.env.NEXT_PUBLIC_SQUARE_DOCS_URL),
    'NEXT_PUBLIC_SQUARE_DOCS_URL'
  );
}

function getCloudProxyHTTPSProxyURL(): string {
  return throwIfUndefined(
    replaceLocalHostPort(process.env.CLOUDPROXY_HTTPS_PROXY),
    'CLOUDPROXY_HTTPS_PROXY'
  );
}

function getNextPublicGATrackingID(): string {
  return throwIfUndefined(
    process.env.NEXT_PUBLIC_GA_TRACKING_ID,
    'NEXT_PUBLIC_GA_TRACKING_ID'
  );
}

function getServerDataApiEndpoint(): string {
  return throwIfUndefined(process.env.DATA_API_ENDPOINT, 'DATA_API_ENDPOINT');
}

function getServerDataApiKey(): string {
  return throwIfUndefined(
    process.env.DATA_API_SERVER_KEY,
    'DATA_API_SERVER_KEY'
  );
}

export {
  getNextPublicGATrackingID,
  convertToEnvironmentUrl,
  getNextPublicBaseURL,
  getNextPublicConnectURL,
  getNextPublicExplorerGatewayURL,
  getCloudProxyHTTPSProxyURL,
  getNextPublicSquareDocsURL,
  getNextPublicSquareURL,
  getNextPublicSquareAppURL,
  getServerDataApiEndpoint,
  getServerDataApiKey,
};
