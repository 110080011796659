import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryFactory } from '@squareup/dex-data-shared-base-api';
import { getNextPublicBaseURL } from '@squareup/dex-utils-environment';

import { ADD_ONS_API_REDUCER_PATH } from './add-ons-constants';
import { addOnsEndpointFactory } from './add-ons-endpoints';
import { AddOnsApiTagTypes } from './add-ons-tag-types';

const baseUrl = getNextPublicBaseURL();

const createAddonsApi = () => {
  const addOnsApi = createApi({
    baseQuery: baseQueryFactory({ baseUrl }),
    endpoints: (_builder) => ({}),
    reducerPath: ADD_ONS_API_REDUCER_PATH,
    tagTypes: Object.values(AddOnsApiTagTypes),
  });

  const injectedApi = addOnsApi.injectEndpoints({
    endpoints: (builder) => addOnsEndpointFactory(builder),
  });

  const addOnsApiStoreConfig = {
    middleware: addOnsApi.middleware,
    reducer: addOnsApi.reducer,
    reducerPath: addOnsApi.reducerPath,
  };

  return { addOnsApi: injectedApi, addOnsApiStoreConfig };
};

let _addOnsApi: ReturnType<typeof createAddonsApi> | undefined;

const getApi = () => {
  if (_addOnsApi) {
    return _addOnsApi;
  }

  _addOnsApi = createAddonsApi();
  return _addOnsApi;
};

const getAddOnsApi = () => {
  return getApi().addOnsApi;
};

const getAddOnsApiStoreConfig = () => {
  return getApi().addOnsApiStoreConfig;
};

export { getAddOnsApi, getAddOnsApiStoreConfig };
