import { BrowserOptions } from '@sentry/react';
import {
  getEnvironmentName,
  isDevelopmentEnvironment,
  isPublicContext,
} from '@squareup/dex-utils-environment';

const sentryConfig: BrowserOptions = {
  environment: getEnvironmentName(),
  dsn: 'https://94d09c8eb5834eb282d762cecbbd40e6@o160250.ingest.sentry.io/4503977342074880',
  tracesSampleRate: 1,
  release: process.env.NEXT_PUBLIC_APP_BUILD_ID || '',
  maxValueLength: 2000,
  enabled: !isDevelopmentEnvironment() && isPublicContext(),
};

export { sentryConfig };
