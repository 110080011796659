'use client';
import {
  Box,
  CodeField,
  Display20,
  Paragraph30,
} from '@squareup/dex-ui-shared-base';
import { ContentLayoutGrid } from '@squareup/dex-ui-shared-content-layout-grid';
import { useDecision } from '@squareup/dex-ui-shared-feature-detection';
import { MarketButton } from '@squareup/dex-ui-shared-market';
import { isDevelopmentEnvironment } from '@squareup/dex-utils-environment';
import React, { FC } from 'react';

import style from './page-error-contents.module.css';

type ErrorPageType = 'not-found' | 'unhandled';

interface PageErrorContentsProps {
  errorType: ErrorPageType;
  onSearchClicked?: () => void;
  showSearchButton: boolean | undefined;
  errorMessage?: string | undefined;
  returnHref: string;
}

const errorTypeToHeading: Record<ErrorPageType, string> = {
  'not-found': 'Page not found',
  unhandled: 'Something went wrong',
};

const errorTypeToText: Record<ErrorPageType, string> = {
  'not-found':
    "We couldn't find a page in the reference for this URL. Try searching, or go back to the home page",
  unhandled:
    "Oops! Looks like we're having an issue. The Square team has been notified and is working hard to get things fixed for you.",
};

const PageErrorContents: FC<PageErrorContentsProps> = ({
  errorType,
  onSearchClicked,
  showSearchButton,
  errorMessage,
  returnHref,
}) => {
  const { decision } = useDecision('error-details');
  const isErrorMessageDetailsEnabled =
    errorMessage && (isDevelopmentEnvironment() || decision.enabled);

  return (
    <ContentLayoutGrid singleColumn={true}>
      <Display20 data-test-error-type={errorType}>
        {errorTypeToHeading[errorType]}
      </Display20>
      <Paragraph30 margin={{ top: '2x' }}>
        {errorTypeToText[errorType]}
      </Paragraph30>
      <Box margin={{ top: '2x' }}>
        {showSearchButton && (
          <Box margin={{ right: '2x' }} className={style.button}>
            <MarketButton
              rank="primary"
              trackingId="error-page-search"
              testId="error-page-search"
              onClick={onSearchClicked}
            >
              Search
            </MarketButton>
          </Box>
        )}
        <MarketButton
          trackingId="error-page-home"
          testId="error-page-home"
          href={returnHref}
        >
          Back to home
        </MarketButton>
      </Box>
      {isErrorMessageDetailsEnabled && (
        <CodeField margin={{ top: '2x' }}>{errorMessage}</CodeField>
      )}
    </ContentLayoutGrid>
  );
};

export { PageErrorContents };
