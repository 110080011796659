// eslint-disable-next-line no-restricted-imports
import { MarketPopover as MarketPopoverBinding } from '@market/react';
import { MarketBindingWithoutClassName } from '@squareup/dex-types-shared-ui';
import clsx from 'clsx';
import React from 'react';

import styles from './market-popover.module.css';

type MarketPopoverProps = MarketBindingWithoutClassName<
  React.ComponentProps<typeof MarketPopoverBinding>
>;

const MarketPopover = React.forwardRef<
  globalThis.HTMLMarketPopoverElement,
  MarketPopoverProps
>(({ children, className, ...rest }, ref) => (
  <MarketPopoverBinding
    ref={ref}
    {...rest}
    className={clsx(styles['market-popover'], className)}
  >
    {children}
  </MarketPopoverBinding>
));

MarketPopover.displayName = 'MarketPopover';

export { MarketPopover };
