import { createContext, useContext } from 'react';

interface LinkContextSearchParamPersistor {
  param: string;
  // Whether to update a given href.
  shouldUpdateHref: (href: string) => boolean;
}

interface LinkContext {
  persistedSearchParams: LinkContextSearchParamPersistor[];
  baseHref?: string;
}

const LinkContextInstance = createContext<LinkContext>({
  persistedSearchParams: [],
});

const LinkContextProvider = LinkContextInstance.Provider;

const useLinkContext = () => {
  return useContext(LinkContextInstance);
};

export {
  LinkContextProvider,
  type LinkContext,
  useLinkContext,
  type LinkContextSearchParamPersistor,
};
