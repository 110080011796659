import {
  StateNodeError,
  StateNodeWithError,
} from '@squareup/dex-types-data-state';
import { NarrowByType } from '@squareup/dex-types-shared-utils';
import {
  extractErrorMessage,
  stateErrorToString,
} from '@squareup/dex-utils-error-formatting';
import { PubSub, EventUnSubscriber } from '@squareup/dex-utils-pub-sub';

import {
  ApplicationBehaviorConfiguration,
  PublishedApplicationEvent,
  ApplicationEvent,
  UserEvent,
  SystemEvent,
  StateEvent,
  TaskEvent,
} from './types';

// An application can have only one global pubsub client.
let _applicationBehaviorPubSub: PubSub | undefined;

// An application can have only one global config.
let _config: ApplicationBehaviorConfiguration | undefined;

/**
 * Subscribes to the application bahavior events of the event type provided.
 * @returns an unsub function.
 */
function subscribe<
  TEvent extends PublishedApplicationEvent,
  TType extends TEvent['type']
>(
  eventType: TType,
  callback: (_event: NarrowByType<TEvent, TType>) => unknown
): EventUnSubscriber {
  if (!_applicationBehaviorPubSub) {
    _applicationBehaviorPubSub = new PubSub();
  }

  return _applicationBehaviorPubSub.subscribe<TEvent, TType>(
    eventType,
    callback
  );
}

/**
 * Publishes an application bahavior event. This is usually not used directly by application code.
 */
function publish(event: ApplicationEvent) {
  if (!_applicationBehaviorPubSub || !_config || _config.silent) {
    return;
  }

  _applicationBehaviorPubSub.publish({
    ...event,
    requestId: _config?.requestId,
  });
}

/**
 * Publishes a system error event. This is useful for publishing unhandled exceptions.
 */
function publishSystemError(event: SystemEvent) {
  publish({
    type: 'system',
    subType: 'error',
    event,
  });
}

function publishSystemTrace(
  event: SystemEvent,
  level: 'debug' | 'info' | 'warn' = 'info'
) {
  publish({
    type: 'system',
    subType: level,
    event,
  });
}

/**
 * Publishes a system data error event. This is useful for publishing errors returned from state transitons.
 */
function publishStateError(error: StateNodeError) {
  publishSystemError({ message: stateErrorToString(error) });
}

/**
 * Publishes a system data error event from the error state.
 */
function publishStateErrorState({ error }: StateNodeWithError<unknown>) {
  if (error) {
    publishStateError(error);
  }
}

/**
 * Publishes an unhandled error as a system error event. This is useful for publishing unexpected errors thrown in the system.
 */
function publishUnhandledError(error: Error) {
  publishSystemError({
    message: extractErrorMessage(error) || JSON.stringify(error),
  });
}

/**
 * Publishes a user action event.
 * README: If you find yourself using this manually something is wrong.
 * All user interactivity on interactable elements is already published
 * automatically by the framework.
 */
function publishUserAction(event: UserEvent) {
  publish({
    type: 'user',
    event,
  });
}

/**
 * Publishes a state action event.
 */
function publishStateAction(event: StateEvent) {
  publish({
    type: 'state',
    event,
  });
}

/**
 * Publishes a thread task event. This is useful for capturing the start or stop of a main thread task.
 * Will auto start unless manuallyStart is true
 */
function publishTaskAction(event: TaskEvent) {
  publish({
    type: 'task',
    event,
  });
}
// TODO: Fix negation of name
/**
 *  Takes a task event and returns a start and end to publish that work.
 */
function initTaskEvent(taskEvent: TaskEvent, startImmediately = true) {
  let started = false;
  const start = () => {
    !started &&
      publishTaskAction({
        ...taskEvent,
        status: 'start',
      });
    started = true;
  };

  const end = () => {
    if (started) {
      publishTaskAction({
        ...taskEvent,
        status: 'end',
      });
    } else {
      // eslint-disable-next-line no-console
      console.error(
        `perf mark ${taskEvent.onIdentifier} ended without starting`
      );
    }
  };

  if (startImmediately) {
    start();
  }

  return { start, end };
}

/**
 * Clears the state of the application bahavior events.
 * This includes removing all consumers.
 */
function clear() {
  _applicationBehaviorPubSub?.clear();
  _applicationBehaviorPubSub = undefined;
}

function configure(config: ApplicationBehaviorConfiguration) {
  clear();
  _config = config;
}

function setRequestId(requestId: string) {
  if (_config) {
    _config.requestId = requestId;
  }
}

export {
  subscribe,
  clear,
  configure,
  publish,
  publishSystemError,
  publishSystemTrace,
  publishUserAction,
  publishStateAction,
  publishStateError,
  publishStateErrorState,
  publishUnhandledError,
  initTaskEvent,
  setRequestId,
};
