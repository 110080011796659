import {
  devsContentApiBaseQuery,
  devsContentEndpoints,
} from '@squareup/dex-data-shared-developer-api';
import { StoreStateNode } from '@squareup/dex-types-data-state';
import { Flags } from '@squareup/dex-types-shared-developer-api';
import {
  publishActionableError,
  stateErrorToCustomError,
} from '@squareup/dex-utils-error';
import {
  BaseQueryArgs,
  mergeQueryArgs,
} from '@squareup/dex-utils-shared-base-api';
import { FeatureProducer } from '@squareup/dex-utils-shared-feature-detection';

/**
 * This flags producer fetches flags from the legacy internal square flagging
 * system go/feature.
 */
class SQFlagsFeatureProducer extends FeatureProducer<Flags> {
  constructor(initialState?: StoreStateNode<{ data: Flags }>) {
    super();

    if (initialState) {
      this.state = initialState;
    }
  }

  public async initialize(
    queryArgs?: BaseQueryArgs
  ): Promise<FeatureProducer<Flags>> {
    // Dont bother initializing if we already have initial state provided
    if (this.state.isLoading) {
      const result = await devsContentApiBaseQuery<Flags>(
        mergeQueryArgs(devsContentEndpoints.getFlags.query?.(), queryArgs)
      );

      if (result.error) {
        this.state = {
          error: stateErrorToCustomError(result.error),
        };
      } else {
        this.state = result;
      }
    }

    publishActionableError(this.state.error);

    return this;
  }
}

export { SQFlagsFeatureProducer };
