import { ApplicationEventConsumerSubscribe } from '@squareup/dex-utils-application-behavior-events';
import { isServerContext } from '@squareup/dex-utils-environment';

const Task = 'task';

function TimingApplicationEventConsumer(
  subscribe: ApplicationEventConsumerSubscribe
) {
  // The server doesnt support timing api's.
  if (!isServerContext()) {
    subscribe(Task, (event) => {
      const {
        event: { action, context, onIdentifier, onType, status },
      } = event;

      const key = `${context}-${onIdentifier}-${onType}-${action}`;
      const statusKey = `${key}-${status}`;

      performance.mark(statusKey);

      if (status === 'end') {
        performance.measure(key, `${key}-start`, `${key}-end`);
      }
    });
  }
}

export { TimingApplicationEventConsumer };
