import { FeatureProducer } from '@squareup/dex-utils-shared-feature-detection';

interface Route {
  searchParams: { [k: string]: string | undefined };
}

type Features = { [key: string]: boolean };

class QSPFlagsFeatureProducer extends FeatureProducer<object> {
  public constructor(private route: Route) {
    super();

    this.state = { data: this.parseQuery(this.route.searchParams) };
  }

  public initialize(): Promise<FeatureProducer<object>> {
    return Promise.resolve(this);
  }

  /**
   * Pull out ?features={'my_flag': { enabled: true }}
   * into a flat list of [key: string]: boolean
   */
  private parseQuery(searchParams: Route['searchParams']) {
    // For now all features are a flat list key/value pair.
    const features = searchParams['features'];

    return this.tryExractFeatures(features);
  }

  /**
   * Validates and extracts the flat structure of Features
   */
  private tryExractFeatures(value: unknown): Features {
    const features: Features = {};

    if (typeof value === 'string') {
      const valueObject = this.getObject(value);
      for (const key in valueObject) {
        if (
          typeof key === 'string' &&
          Object.prototype.hasOwnProperty.call(valueObject, key)
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const fieldValue = (valueObject as any)[key];
          const enabledField = (fieldValue as Features)['enabled'];
          if (typeof enabledField === 'boolean') {
            features[key] = enabledField;
          }
        }
      }
    }

    return features;
  }

  private getObject(value: string): object | undefined {
    try {
      return JSON.parse(value);
    } catch {
      // Eat it we dont care.
      return undefined;
    }
  }
}

export { QSPFlagsFeatureProducer };
