import { publishStateAction } from '@squareup/dex-utils-application-behavior-events';
import React, { FC, ReactNode, useEffect, useState } from 'react';

interface ScrollDepthReporterProps {
  children: ReactNode;
  entityIdentifier: string;
}

const depthPercentageThresholds: number[] = [100, 75, 50, 25, 10];

/**
 * ScrollDepthReporter wraps any children ReactNode passed into it.
 * It set up a `scroll` event listener that reports to eventstream when
 * the user scroll passed predetermined thresholds.
 *
 * We set up the `scroll` event listener within our useEffect that has a
 * dependency on the children prop.This makes it so that the event listener
 * is applied on the first mount and on subsequent re-renders due to the passed
 * in children being different. The event listener is destroyed on the final
 * unmount aand before each re-render.
 * @param ScrollDepthReporterProps
 * @returns ScrollDepthReporterProps.children
 */
const ScrollDepthReporter: FC<ScrollDepthReporterProps> = ({
  children,
  entityIdentifier,
}) => {
  const [_, setMaxThresholdReached] = useState<number>(0);

  // Add scroll event listener on mount
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const fullHeight = document.body.scrollHeight;

      // Calculate depth percentage
      const rawDepthPercent =
        (scrollPosition / (fullHeight - windowHeight)) * 100;

      // Javascript and scroll position is imprecise, rounding to account for that.
      const depthPercent = Math.round(rawDepthPercent);

      // Find the largest threshold we're in
      const currentThreshold = depthPercentageThresholds.find(
        (percentage) => depthPercent >= percentage
      );

      // Only record when we've gone past the current threshold
      if (currentThreshold !== undefined) {
        setMaxThresholdReached((maxThresholdReached) => {
          if (currentThreshold > maxThresholdReached) {
            // Publish event
            publishStateAction({
              action: 'scroll',
              onIdentifier: entityIdentifier,
              onType: 'page',
              extra: JSON.stringify({ scrollDepth: currentThreshold }),
            });

            // Set new max threshold
            return currentThreshold;
          }

          // Leave previous value unchanged
          return maxThresholdReached;
        });
      }
    };
    window.addEventListener('scroll', handleScroll);

    // remove event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      setMaxThresholdReached(0); // If we re-render set the depth back to zero
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]); // Re-render when new children are passed in

  return <>{children}</>;
};

export { ScrollDepthReporter };
