/**
 * Non Error Codes
 */
const FOUND_CODE = 302;

/**
 * Error Codes
 */
const INTERNAL_SERVER_ERROR_CODE = 500;
const INTERNAL_SERVER_ERROR_MESSAGE = 'Internal server error';

const UNAUTHORIZED_ERROR_CODE = 401;
const NOT_FOUND_ERROR_CODE = 404;
const FORBIDDEN_ERROR_CODE = 403;

const ERROR_HTTP_CODE_DESCRIPTIONS = new Map([
  [400, 'Bad request'],
  [UNAUTHORIZED_ERROR_CODE, 'Unauthorized'],
  [402, 'Payment required'],
  [FORBIDDEN_ERROR_CODE, 'Forbidden'],
  [NOT_FOUND_ERROR_CODE, 'Not found'],
  [405, 'Method not allowed'],
  [406, 'Not acceptable'],
  [407, 'Proxy authentication required'],
  [408, 'Request timeout'],
  [409, 'Conflict'],
  [410, 'Gone'],
  [411, 'Length required'],
  [412, 'Precondition failed'],
  [413, 'Payload too large'],
  [414, 'URI too long'],
  [415, 'Unsupported media type'],
  [416, 'Range not satisfiable'],
  [417, 'Expectation failed'],
  [418, "I'm a teapot"],
  [421, 'Misdirected request'],
  [422, 'Unprocessable entity'],
  [423, 'Locked'],
  [424, 'Failed dependency'],
  [425, 'Too early'],
  [426, 'Upgrade required'],
  [428, 'Precondition required'],
  [429, 'Too many requests'],
  [431, 'Request header fields too large'],
  [451, 'Unavailable for legal reasons'],
  [INTERNAL_SERVER_ERROR_CODE, INTERNAL_SERVER_ERROR_MESSAGE],
  [501, 'Not implemented'],
  [502, 'Bad gateway'],
  [503, 'Service unavailable'],
  [504, 'Gateway timeout'],
  [505, 'HTTP version not supported'],
  [506, 'Variant also negotiates'],
  [507, 'Insufficient storage'],
  [508, 'Loop detected'],
  [510, 'Not extended'],
  [511, 'Network authentication required'],
]);

export {
  ERROR_HTTP_CODE_DESCRIPTIONS,
  INTERNAL_SERVER_ERROR_CODE,
  INTERNAL_SERVER_ERROR_MESSAGE,
  UNAUTHORIZED_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
  FOUND_CODE,
  NOT_FOUND_ERROR_CODE,
};
