// eslint-disable-next-line no-restricted-imports
import { MarketButton as MarketButtonBinding } from '@market/react';
import { MarketBindingWithoutClassName } from '@squareup/dex-types-shared-ui';
import { TestProps, TrackedProps } from '@squareup/dex-types-shared-utils';
import clsx from 'clsx';
import React from 'react';

import styles from './market.module.css';

type ButtonProps = MarketBindingWithoutClassName<
  React.ComponentProps<typeof MarketButtonBinding>
> &
  TestProps &
  TrackedProps;

const MarketButton = React.forwardRef<
  globalThis.HTMLMarketButtonElement,
  ButtonProps
>(
  (
    { children, testId, trackingId, trackingExtra, className, ...rest },
    ref
  ) => {
    return (
      <MarketButtonBinding
        {...rest}
        ref={ref}
        data-testid={testId}
        data-tracking-id={trackingId}
        data-tracking-extra={trackingExtra}
        className={clsx(styles['market-button'], className)}
      >
        {children}
      </MarketButtonBinding>
    );
  }
);

MarketButton.displayName = 'MarketButton';

export { MarketButton };
