import { ApplicationEventTrackingQueue } from '@squareup/dex-utils-application-behavior-event-tracking';
import { ApplicationEventConsumerSubscribe } from '@squareup/dex-utils-application-behavior-events';
import { isServerContext } from '@squareup/dex-utils-environment';
import { initializeDatadogLogs } from '@squareup/dex-utils-shared-real-user-monitoring';

import { getCategory, getLabel, getName } from './consumer-helpers';

const System = 'system';
const User = 'user';
const State = 'state';
const Task = 'task';

interface LoggingApplciationEventConsumerOptions {
  service: string;
  datadogClientToken: string;
  appVersion: string;
  environment: string;
}

interface Logger {
  info(message: string, context: object): void;
  debug(message: string, context: object): void;
  error(message: string, context: object): void;
  warn(message: string, context: object): void;
}

function toLogArgs(
  message: string,
  context?: object | undefined
): [string, object] {
  return [
    `${message}${context ? ` - ${JSON.stringify(context, null, 2)}` : ''}`,
    context || {},
  ];
}

function getServerLogger(): Logger | null {
  return globalThis.serverLogger || null;
}

async function setupDatadog(
  options: LoggingApplciationEventConsumerOptions
): Promise<Logger | null> {
  const datadogLogs = await initializeDatadogLogs({
    clientToken: options.datadogClientToken,
    env: options.environment,
    version: options.appVersion,
    service: options.service,
  });

  if (!datadogLogs) {
    return null;
  }

  return new Promise((resolve) => {
    datadogLogs.onReady(() => {
      resolve(datadogLogs.logger);
    });
  });
}

/**
 * On the client side, the logger must wait for startup, and respect the OneTrust consent.
 * We will enforce both through the application event queue
 * Afterwards, this will return a wrapper around the Datadog logger which queues log messages
 * @param options
 * @returns
 */
function getClientLogger(
  options: LoggingApplciationEventConsumerOptions
): Logger {
  const datadogPromise = setupDatadog(options);
  const queue = new ApplicationEventTrackingQueue('system', datadogPromise);

  const logToQueue = (
    level: 'debug' | 'info' | 'warn' | 'error',
    message: string,
    context: object
  ) => {
    queue.enqueue(async () => {
      const datadogLogger = await datadogPromise;
      if (!datadogLogger) {
        return;
      }

      datadogLogger[level](message, context);
    });
  };

  const queuedLogger: Logger = {
    debug: (message: string, context: object) =>
      logToQueue('debug', message, context),
    info: (message: string, context: object) =>
      logToQueue('info', message, context),
    warn: (message: string, context: object) =>
      logToQueue('warn', message, context),
    error: (message: string, context: object) =>
      logToQueue('error', message, context),
  };

  return queuedLogger;
}

function LoggingApplicationEventConsumer(
  subscribe: ApplicationEventConsumerSubscribe,
  options: LoggingApplciationEventConsumerOptions
) {
  const logger = isServerContext()
    ? getServerLogger()
    : getClientLogger(options);

  if (!logger) {
    return;
  }

  subscribe(System, (event) => {
    const {
      subType,
      event: { message, context, error },
      requestId,
    } = event;

    logger[subType](
      ...toLogArgs(message, {
        ...context,
        requestId,
        ...(error ? { error } : {}),
      })
    );
  });

  subscribe(State, (stateEvent) => {
    const { type, event, requestId } = stateEvent;
    const name = getName(event);
    const label = getLabel(event) || '';

    logger.info(
      ...toLogArgs(State, {
        name,
        label,
        type,
        requestId,
      })
    );
  });

  subscribe(User, (userEvent) => {
    const { type, event, requestId } = userEvent;
    const { action, extra } = event;

    let context: object = {
      action,
      extra,
      requestId,
    };

    if (action !== 'navigate') {
      const name = getName(event);
      const category = getCategory(type, event);
      const label = getLabel(event) || '';

      context = {
        ...context,
        name,
        category,
        label,
      };
    }

    logger.info(...toLogArgs(User, context));
  });

  const tasks = new Map();
  subscribe(Task, (taskEvent) => {
    const { type, event, requestId } = taskEvent;
    const name = getName(event);
    const category = getCategory(type, event);

    const taskKey = `|name:${name}| |category:${type}| |requestId:${requestId}|`;
    if (event.status === 'start') {
      if (!tasks.has(taskKey)) {
        tasks.set(taskKey, Date.now());
      }
    } else {
      if (tasks.has(taskKey)) {
        const started = tasks.get(taskKey);
        const elapsed = Date.now() - started;

        logger.debug(
          ...toLogArgs(Task, {
            name,
            category,
            elapsed: `${elapsed}(ms)`,
            requestId,
          })
        );

        tasks.delete(taskKey);
      }
    }
  });
}

export { LoggingApplicationEventConsumer };
