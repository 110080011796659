import {
  isOneTrustCategoryConsented,
  nowAndOnOneTrustConsentChanged,
  OneTrustCategory,
} from '@square/onetrust-compliant-access';
import { ApplicationEventType } from '@squareup/dex-utils-application-behavior-events';

import { applicationEventOneTrustCategoryMap } from './config';

type TrackRequest = {
  resolve: () => void;
  reject?: ((reason: string) => void) | undefined;
};

/**
 * A queue which is responsible for taking user tracking callbacks and flushes them.
 * Once reaching MAX_QUEUE_SIZE oldest items will be rejected from the queue.
 */
class ApplicationEventTrackingQueue {
  // A queue of track requests
  private queue: TrackRequest[];
  private hasConsentedToTrack: boolean;
  private flushBegin: Promise<unknown> | undefined;
  private category: OneTrustCategory | undefined;

  // When set we will never enque as we will never flush
  private willNeverFlushReason: string | undefined;

  /**
   * Queues all track requests until they are able to be released.
   * @param eventType - the event type tracked
   * @param flushBegin - a promise which will when resolved will allow for the queue to flush iteself.
   */
  constructor(
    private eventType: ApplicationEventType,
    flushBegin?: Promise<unknown> | undefined,
    private maxQueueSize: number = 1000
  ) {
    this.queue = [];
    this.flushBegin = flushBegin;
    this.hasConsentedToTrack = false;
    this.category = applicationEventOneTrustCategoryMap.get(this.eventType);

    this.willNeverFlushReason = this.category
      ? undefined
      : `${this.eventType} does not exist in application event one trust category map`;

    if (!this.willNeverFlushReason) {
      this.flushAfterFlushBegin();
      this.flushOnConsentChange();
    }
  }

  /**
   * Executes the given callback upon flush of the queue, until then it's queued.
   * @param callback - a callback to execute.
   * @param reject - a callback to execute upon begin failure.
   */
  public enqueue(callback: () => void, reject?: (reason: string) => void) {
    // Dont bother queing if we can never flush it that will just increase memory
    if (this.willNeverFlushReason) {
      reject && reject(this.willNeverFlushReason);
      return;
    }

    this.queue.push({ resolve: callback, reject });
    this.flushAfterFlushBegin();

    if (this.queue.length > this.maxQueueSize) {
      this.rejectFromFrontOfQueue(
        `Reached max queue size of ${this.maxQueueSize} rejecting all event to stay under size`
      );
    }
  }

  /**
   * On consent changes set local state to know if the user should be tracked and flushes the queue on change
   */
  private flushOnConsentChange(): void {
    nowAndOnOneTrustConsentChanged(() => {
      this.hasConsentedToTrack = Boolean(
        this.category && isOneTrustCategoryConsented(this.category)
      );
      this.flushAfterFlushBegin();
    });
  }

  /**
   * Flushes behind the afterFlush promise if supplied otherwise flushes immediately.
   */
  private flushAfterFlushBegin() {
    if (this.flushBegin) {
      this.flushBegin
        .then(() => {
          this.flush();
        })
        .catch(() => {
          this.willNeverFlushReason = `Event tracking queue will never flush. Flush begin rejected for event type ${this.eventType}`;
          this.flush();
        });
    } else {
      this.flush();
    }
  }

  private flush(): void {
    if (!this.hasConsentedToTrack) {
      return;
    }

    // If the user has consented or we are rejecting everything empty the queue.
    while (this.queue.length > 0) {
      if (this.willNeverFlushReason) {
        const reject = this.queue.pop()?.reject;
        reject && reject(this.willNeverFlushReason);
      } else {
        this.queue.pop()?.resolve();
      }
    }
  }

  private rejectFromFrontOfQueue(reason: string) {
    const event = this.queue.shift();
    if (event) {
      event.reject && event.reject(reason);
    }
  }
}

export { ApplicationEventTrackingQueue };
