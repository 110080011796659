import { AsProp, NullableClassName } from '@squareup/dex-types-shared-ui';
import { TestProps, TrackedProps } from '@squareup/dex-types-shared-utils';
import React, { ReactNode } from 'react';

const DynamicTag = React.forwardRef<
  unknown,
  NullableClassName &
    AsProp &
    Partial<TrackedProps> &
    TestProps & {
      defaultTag: string;
      children?: ReactNode | undefined;
    } & React.HTMLAttributes<HTMLElement> &
    React.DOMAttributes<HTMLElement>
>(
  (
    {
      as,
      defaultTag,
      className,
      children,
      testId,
      trackingId,
      trackingExtra,
      ...rest
    },
    ref
  ) => {
    const tag = as ? as : defaultTag;

    return React.createElement(
      tag,
      {
        'data-testid': testId,
        'data-tracking-id': trackingId,
        'data-tracking-extra': trackingExtra,
        ...rest,
        className: className || undefined,
        ref,
      },
      children
    );
  }
);
DynamicTag.displayName = 'DynamicTag';

export { DynamicTag };
