import { ForwardedRef, MutableRefObject } from 'react';

/**
 * The point of this function is to be able to set both a local
 * `useRef`, along with a React.forwardRef ref.
 * If we want to pass a forwarded ref to a component, you must use
 * React.forwardRef. But now, what if we also want to manipulate the
 * ref within the component too?
 * In that case, we need a function that will set them both. The problem is,
 * a forwarded ref is either a function or an actual object. So we must check both.
 *
 * This function therefore serves the purpose to be able to set both refs at once.
 * @param ref A local ref created by `useRef`
 * @param forwardedRef A ref forwarded through React.forwardRef
 */
const setLocalAndForwardedRef = <T>(
  ref: MutableRefObject<T | undefined>,
  forwardedRef: ForwardedRef<T>
) => {
  return (element: T) => {
    // eslint-disable-next-line no-param-reassign
    ref.current = element;
    if (typeof forwardedRef === 'function') {
      forwardedRef(element);
    } else if (forwardedRef) {
      // eslint-disable-next-line no-param-reassign
      forwardedRef.current = element;
    }
  };
};

export { setLocalAndForwardedRef };
