import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { TestProps } from '@squareup/dex-types-shared-utils';
import { Box } from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, { FunctionComponent, MouseEventHandler, ReactNode } from 'react';

import itemStyles from './grid-item.module.css';
import styles from './grid.module.css';

interface GridItemRenderProps {
  styles: typeof itemStyles;
}

type GridVariant =
  | 'app'
  | 'app-single'
  | 'content'
  | 'content-single'
  | 'docs-app'
  | 'docs-content'
  | 'devs-console-app'
  | 'integration-check-modal-ftoc';
type GridVariantStyle =
  | 'dex-app-grid-container'
  | 'dex-content-grid-container'
  | 'dex-docs-app-grid-container'
  | 'dex-docs-content-grid-container'
  | 'dex-devs-console-app-grid-container'
  | 'dex-left-ftoc-grid-container';

interface GridProps {
  variant: GridVariant;
  children?: ReactNode;
  render?: FunctionComponent<GridItemRenderProps>;
  onMouseOver?: MouseEventHandler | undefined;
}

const styleMap = new Map<GridVariant, GridVariantStyle>([
  ['app', 'dex-app-grid-container'],
  ['app-single', 'dex-app-grid-container'],
  ['content', 'dex-content-grid-container'],
  ['content-single', 'dex-content-grid-container'],
  ['docs-app', 'dex-docs-app-grid-container'],
  ['docs-content', 'dex-docs-content-grid-container'],
  ['devs-console-app', 'dex-devs-console-app-grid-container'],
  ['integration-check-modal-ftoc', 'dex-left-ftoc-grid-container'],
]);

const Grid: FunctionComponent<GridProps & NullableClassName & TestProps> = ({
  variant,
  children,
  render,
  onMouseOver,
  className = '',
  testId,
}) => {
  // The content containers are the same, except for a single override
  const styleName = styleMap.get(variant);
  if (!styleName) {
    throw new Error(`${variant} is not a valid grid variant`);
  }

  return (
    <Box
      className={clsx(
        styles['dex-grid-container'],
        styles[styleName],
        variant === 'content-single' &&
          styles['dex-content-single-grid-container'],
        variant === 'app-single' && styles['dex-app-single-grid-container'],
        className
      )}
      onMouseOver={onMouseOver}
      testId={testId}
    >
      {children}
      {render && render({ styles: itemStyles })}
    </Box>
  );
};

export { Grid, type GridItemRenderProps, type GridProps };
