import { captureException, init, Event } from '@sentry/nextjs';
import { ApplicationEventTrackingQueue } from '@squareup/dex-utils-application-behavior-event-tracking';
import { ApplicationEventConsumerSubscribe } from '@squareup/dex-utils-application-behavior-events';
import {
  getCloudProxyHTTPSProxyURL,
  isPublicContext,
  isServerContext,
} from '@squareup/dex-utils-environment';

const System = 'system';

type SentryOptions = Parameters<typeof init>[0];

function getErrorFromString(message: string): Error {
  try {
    throw new Error(message);
  } catch (error) {
    return error as Error;
  }
}

function SentryApplicationEventConsumer(
  sentryConfig: SentryOptions,
  subscribe: ApplicationEventConsumerSubscribe,
  cloudProxyUrl?: string
) {
  let options: SentryOptions;

  if (!isServerContext()) {
    const queue = new ApplicationEventTrackingQueue(System);

    options = {
      ...sentryConfig,
      beforeSend: (event: Event) => {
        return new Promise((resolve, reject) => {
          queue.enqueue(() => resolve(event), reject);
        });
      },
    };
  } else {
    // We must still initialize the Sentry instance here on
    // the server, otherwise it will not publish events server side
    const httpProxy = cloudProxyUrl || getCloudProxyHTTPSProxyURL();

    options = {
      ...sentryConfig,
      transportOptions: {
        proxy: httpProxy,
      },
    };
  }

  // Only need to stand up sentry if in a public context.
  // Unfortunately Sentry sends events on its own just by init.
  isPublicContext() && init(options);

  // Subscribe to app events and transform them.
  subscribe(System, (event) => {
    const {
      subType,
      event: { message, error },
    } = event;

    // We only care about errors for now.
    if (subType === 'error') {
      // If no error we need to create one from the message to capture a stack
      if (!error) {
        captureException(getErrorFromString(message));
      } else {
        const context = { extra: { message } };
        if (error instanceof Error) {
          captureException(error, context);
        } else if (typeof error === 'string') {
          captureException(getErrorFromString(error), context);
        } else {
          // Not sure what was passed for error so ignore it.
          captureException(getErrorFromString(message));
        }
      }
    }
  });
}

export { SentryApplicationEventConsumer };
