import { Box, Paragraph10 } from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import styles from './popover.module.css';

interface LinkPreviewPopoverLayoutProps {
  footer: ReactNode;
}

const LinkPreviewPopoverLayout: FC<
  PropsWithChildren<LinkPreviewPopoverLayoutProps>
> = ({ children, footer }) => {
  return (
    <>
      <Box
        testId="link-preview-layout-content"
        as="span"
        className={styles['limit-lines']}
      >
        {children}
      </Box>
      <Paragraph10
        as="span"
        className={clsx(
          styles['link-preview-popover-layout'],
          styles['inverse-color']
        )}
        margin={{ top: '1x' }}
        padding={{ top: '0.75x' }}
        border={{ line: { top: '10' } }}
      >
        {footer}
      </Paragraph10>
    </>
  );
};

export { LinkPreviewPopoverLayout };
