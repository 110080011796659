import {
  BaseEndpointBuilder,
  endpointBuilder,
} from '@squareup/dex-data-shared-base-api';
import {
  DeveloperContactContentTypes,
  DeveloperContactRequest,
  DeveloperContact,
  DeveloperContactPayload,
} from '@squareup/dex-types-shared-developer-contacts';
import { HTTP_METHOD } from '@squareup/dex-types-shared-http';

import { DEVELOPER_API_REDUCER_PATH } from './developer-api-constants';
import { DeveloperApiTagTypes } from './developer-api-tag-types';

const DEV_CONTACTS_API_URL = '/developer-contacts';
const appName = 'developer-contacts';

/**
 * Transforms the response from the API to include content types for each contact
 * @param response DeveloperContactPayload
 * @returns DeveloperContactPayload
 */
const transformContactsResponse = (response: DeveloperContactPayload) => {
  let { contacts } = response;
  const { app_marketplace_contacts } = response;

  // Set content type for each contact to DEV_NEWSLETTER
  contacts = contacts.map((contact: DeveloperContact) => ({
    ...contact,
    content_types: [DeveloperContactContentTypes.DEV_NEWSLETTER],
  }));

  // Set content type for each contact to APP_MARKETPLACE_REVIEWS
  // If there is a matching contact (by email), add APP_MARKETPLACE_REVIEWS to its content types
  app_marketplace_contacts.forEach((appMarketplaceContact) => {
    const contactWithSameEmail = contacts.find(
      (contact) => contact.email_address === appMarketplaceContact.email_address
    );
    if (contactWithSameEmail) {
      contactWithSameEmail.content_types.push(
        DeveloperContactContentTypes.APP_MARKETPLACE_REVIEWS
      );
    } else {
      contacts.push({
        ...appMarketplaceContact,
        content_types: [DeveloperContactContentTypes.APP_MARKETPLACE_REVIEWS],
      });
    }
  });

  return {
    ...response,
    contacts,
  };
};

const developerContactsEndpointFactory = (
  builder: BaseEndpointBuilder<typeof DEVELOPER_API_REDUCER_PATH>
) => ({
  getDeveloperContacts: builder.query<DeveloperContactPayload, void>({
    query: () => ({
      path: DEV_CONTACTS_API_URL,
      appName,
    }),
    transformResponse: transformContactsResponse,
    providesTags: [DeveloperApiTagTypes.DeveloperContacts],
  }),

  addDeveloperContact: builder.mutation<
    DeveloperContact,
    DeveloperContactRequest
  >({
    query: ({ email_address, content_types }) => ({
      path: DEV_CONTACTS_API_URL,
      appName,
      method: HTTP_METHOD.POST,
      additionalHeaders: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email_address,
        content_types,
      }),
    }),
    invalidatesTags: [DeveloperApiTagTypes.DeveloperContacts],
  }),

  deleteDeveloperContact: builder.mutation<undefined, { uuid: string }>({
    query: ({ uuid }) => ({
      path: `${DEV_CONTACTS_API_URL}/${uuid}`,
      appName,
      method: HTTP_METHOD.DELETE,
    }),
    invalidatesTags: [DeveloperApiTagTypes.DeveloperContacts],
  }),
});

const developerContactsEndpoints =
  developerContactsEndpointFactory(endpointBuilder);

export { developerContactsEndpoints, developerContactsEndpointFactory };
