import {
  BaseQuery,
  BaseEndpointBuilder,
  endpointBuilder,
} from '@squareup/dex-data-shared-base-api';
import {
  CodeExampleContent,
  ReferenceCodeExamples,
  ReferenceSpecification,
} from '@squareup/dex-types-oas';
import {
  DEFAULT_SPEC_GRAMMAR,
  ExamplesVersionInfo,
  SpecificationVersionInfo,
} from '@squareup/dex-types-oas-path';
import {
  Domain,
  DomainSummaries,
  QuestionResponse,
  Summary,
} from '@squareup/dex-types-shared-app-launch';
import { Flags } from '@squareup/dex-types-shared-developer-api';
import {
  DocPage,
  DocRoute,
  DocType,
  NavSet,
  ReleaseTrain,
} from '@squareup/dex-types-shared-docs';
import { HTTP_METHOD } from '@squareup/dex-types-shared-http';
import { SearchRecord } from '@squareup/dex-types-shared-search';
import { BaseQueryArgs } from '@squareup/dex-utils-shared-base-api';

import { DEVELOPER_API_REDUCER_PATH } from './developer-api-constants';

const DEVS_CONTENT_API_URL = '/api/devs-content';
const DEVS_CONTENT_CONTENT_API_URL = `${DEVS_CONTENT_API_URL}/content`;
const DEVS_CONTENT_SEARCH_API_URL = `${DEVS_CONTENT_API_URL}/search`;
const devsContentAppName = 'devs-content';

const devsContentEndpointFactory = (
  builder: BaseEndpointBuilder<typeof DEVELOPER_API_REDUCER_PATH>
) => ({
  /**
   * Fetches a specification given its version info.
   */
  getSpecification: builder.query<
    ReferenceSpecification,
    SpecificationVersionInfo
  >({
    query: (options: SpecificationVersionInfo) => {
      let path = DEVS_CONTENT_CONTENT_API_URL;
      let metricName;
      if (options?.isUnpublished) {
        path += '/unpublished';
      }

      path += `/${options?.grammar || DEFAULT_SPEC_GRAMMAR}`;

      if (options?.version) {
        metricName = `${path}/{version}`;
        path += `/${options.version}`;
      } else {
        path += '/latest';
        metricName = path;
      }

      return {
        path,
        useCache: true,
        metricsHandlerName: metricName,
        appName: devsContentAppName,
      };
    },
  }),

  /**
   * Fetches all specification versions.
   */
  getSpecificationVersions: builder.query<string[], undefined>({
    query: () => ({
      path: `${DEVS_CONTENT_CONTENT_API_URL}/${DEFAULT_SPEC_GRAMMAR}`,
      useCache: true,
      appName: devsContentAppName,
    }),
  }),

  /**
   * Fetches code examples given version info.
   */
  getExamples: builder.query<CodeExampleContent, ExamplesVersionInfo>({
    queryFn: async (
      options: ExamplesVersionInfo,
      _api: unknown,
      _meta: unknown,
      baseQuery: BaseQuery
    ) => {
      let path = DEVS_CONTENT_CONTENT_API_URL;
      let metricName;
      if (options?.isUnpublished) {
        path += '/unpublished';
      }

      path += `/${options?.grammar || 'examples'}`;

      if (options?.version) {
        metricName = `${path}/{version}`;
        path += `/${options.version}`;
      } else {
        path += '/latest';
        metricName = path;
      }

      const result = await baseQuery<ReferenceCodeExamples>({
        path,
        useCache: true,
        metricsHandlerName: metricName,
        appName: devsContentAppName,
      });

      if (result.error) {
        return result;
      }

      try {
        const data = result.data;
        const examples = JSON.parse(data.content.content) as CodeExampleContent;

        return {
          data: examples,
        };
      } catch (error) {
        return {
          error: {
            data: `Request url: ${path}`,
            error: `Unable to parse examples - ${error}`,
            originalStatus: 500,
            status: 'PARSING_ERROR',
          },
        };
      }
    },
  }),

  /**
   * Doc page endpoints
   */
  getDocPage: builder.query<
    DocPage,
    {
      slug: string;
      type: Exclude<DocType, DocType.NavSet>;
      preview: boolean;
      releaseTrain?: string | undefined;
      environment?: string | undefined;
      cookie?: string | undefined;
    }
  >({
    queryFn: async (options, _api, _meta, baseQuery: BaseQuery) => {
      const subpath = options.type === 'doc-page' ? 'pages/slug' : options.type;

      const searchParams = new URLSearchParams();
      if (options.preview) {
        searchParams.append('preview', 'true');
      }

      if (options.releaseTrain) {
        searchParams.append('train', options.releaseTrain);
      }

      if (options.environment) {
        searchParams.append('environment', options.environment);
      }

      const qspString =
        [...searchParams.keys()].length > 0
          ? `?${searchParams.toString()}`
          : '';
      const path = `${DEVS_CONTENT_CONTENT_API_URL}/docs/${subpath}/${options.slug}${qspString}`;
      const queryArgs: BaseQueryArgs = {
        path,
        metricsHandlerName: `${DEVS_CONTENT_CONTENT_API_URL}/docs/${subpath}/{slug}${qspString}`,
        appName: devsContentAppName,
      };
      if (options.cookie) {
        queryArgs.additionalHeaders = {
          cookie: options.cookie,
        };
      }
      const result = await baseQuery<DocPage>(queryArgs);
      return result;
    },
  }),
  getDocsNavSet: builder.query<
    NavSet,
    {
      preview: boolean;
      releaseTrain?: string | undefined;
      environment?: string | undefined;
      cookie?: string | undefined;
    }
  >({
    queryFn: async (options, _api, _meta, baseQuery: BaseQuery) => {
      const searchParams = new URLSearchParams();
      if (options.preview) {
        searchParams.append('preview', 'true');
      }

      if (options.releaseTrain) {
        searchParams.append('train', options.releaseTrain);
      }

      if (options.environment) {
        searchParams.append('environment', options.environment);
      }

      const qspString =
        [...searchParams.keys()].length > 0
          ? `?${searchParams.toString()}`
          : '';
      const path = `${DEVS_CONTENT_CONTENT_API_URL}/docs/${DocType.NavSet}${qspString}`;

      const queryArgs: BaseQueryArgs = {
        path,
        metricsHandlerName: `${path}${qspString}`,
        appName: devsContentAppName,
      };
      if (options.cookie) {
        queryArgs.additionalHeaders = {
          cookie: options.cookie,
        };
      }
      const result = await baseQuery<NavSet>(queryArgs);
      return result;
    },
  }),
  getDocsReleaseTrain: builder.query<ReleaseTrain, { slug: string }>({
    queryFn: async (
      options: {
        slug: string;
      },
      _api,
      _meta,
      baseQuery: BaseQuery
    ) => {
      const path = `${DEVS_CONTENT_CONTENT_API_URL}/docs/trains/${options.slug}`;
      const result = await baseQuery<ReleaseTrain>({
        path,
        metricsHandlerName: `${DEVS_CONTENT_CONTENT_API_URL}/docs/trains/{slug}`,
        appName: devsContentAppName,
      });

      return result;
    },
  }),
  listDocRoutes: builder.query<DocRoute[], Record<string, unknown>>({
    query: () => {
      const path = `${DEVS_CONTENT_CONTENT_API_URL}/docs/pages`;
      return {
        path,
        metricsHandlerName: `${DEVS_CONTENT_CONTENT_API_URL}/docs/pages`,
        appName: devsContentAppName,
      };
    },
  }),
  searchQuestion: builder.query<
    QuestionResponse,
    {
      questionIds: string[];
      preview?: boolean;
      environment?: string;
    }
  >({
    query: ({ questionIds, preview, environment }) => {
      const searchParams = new URLSearchParams();
      if (preview) {
        searchParams.append('preview', 'true');
      }
      if (environment) {
        searchParams.append('environment', environment);
      }
      const qspString =
        [...searchParams.keys()].length > 0
          ? `?${searchParams.toString()}`
          : '';

      const path = `${DEVS_CONTENT_CONTENT_API_URL}/app-launch/questions/search${qspString}`;

      return {
        path,
        metricsHandlerName: `${DEVS_CONTENT_CONTENT_API_URL}/app-launch/questions/search${qspString}`,
        appName: devsContentAppName,
        method: HTTP_METHOD.POST,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ questionIds }),
      };
    },
  }),
  getDomain: builder.query<
    Domain,
    {
      domain_id: string;
      preview?: boolean | undefined;
      environment?: string | undefined;
    }
  >({
    query: ({ domain_id, preview, environment }) => {
      const searchParams = new URLSearchParams();
      if (preview) {
        searchParams.append('preview', 'true');
      }
      if (environment) {
        searchParams.append('environment', environment);
      }
      const qspString =
        [...searchParams.keys()].length > 0
          ? `?${searchParams.toString()}`
          : '';

      const path = `${DEVS_CONTENT_CONTENT_API_URL}/app-launch/domains/${domain_id}${qspString}`;

      return {
        path,
        metricsHandlerName: `${DEVS_CONTENT_CONTENT_API_URL}/app-launch/domains/${domain_id}${qspString}`,
        appName: devsContentAppName,
      };
    },
  }),
  listDomainSummaries: builder.query<
    DomainSummaries,
    {
      preview?: boolean;
      environment?: string;
    }
  >({
    query: ({ preview, environment }) => {
      const searchParams = new URLSearchParams();
      if (preview) {
        searchParams.append('preview', 'true');
      }
      if (environment) {
        searchParams.append('environment', environment);
      }
      const qspString =
        [...searchParams.keys()].length > 0
          ? `?${searchParams.toString()}`
          : '';

      const path = `${DEVS_CONTENT_CONTENT_API_URL}/app-launch/domains/summary${qspString}`;
      return {
        path,
        metricsHandlerName: `${DEVS_CONTENT_CONTENT_API_URL}/app-launch/domains/summary${qspString}`,
        appName: devsContentAppName,
      };
    },
    transformResponse: (response: DomainSummaries) => {
      return {
        summaries: [...response.summaries].sort(
          (firstSummary: Summary, secondSummary: Summary) => {
            if (firstSummary.title < secondSummary.title) {
              return -1;
            } else {
              return 1;
            }
          }
        ),
      };
    },
  }),
  getSearchRecord: builder.query<
    SearchRecord,
    { url: string; filterText?: boolean }
  >({
    query: (options) => {
      const params = new URLSearchParams();
      params.append('url', options.url);
      if (options.filterText) {
        params.append('filter_text', 'true');
      }

      const path = `${DEVS_CONTENT_SEARCH_API_URL}/records?${params.toString()}`;
      return {
        path,
        metricsHandlerName: `${DEVS_CONTENT_SEARCH_API_URL}/records`,
        appName: devsContentAppName,
      };
    },
  }),

  getFlags: builder.query<Flags, void>({
    query: () => ({
      path: `${DEVS_CONTENT_CONTENT_API_URL}/flags`,
      devsContentAppName,
    }),
  }),
});

const devsContentEndpoints = devsContentEndpointFactory(endpointBuilder);

export { devsContentEndpoints, devsContentEndpointFactory };
