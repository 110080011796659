import { LinkPreviewer } from '@squareup/dex-feature-shared-main-link-preview';
import { PageErrorContents } from '@squareup/dex-feature-shared-main-page-contents';
import { ScrollDepthReporter } from '@squareup/dex-feature-shared-main-scroll-depth-reporter';
import { MarkdownContextProvider } from '@squareup/dex-markdown-context-provider';
import { StateNodeError } from '@squareup/dex-types-data-state';
import { ServerAppInitResults } from '@squareup/dex-types-shared-app';
import { Flags } from '@squareup/dex-types-shared-developer-api';
import { Link, UIProvider } from '@squareup/dex-ui';
import { Box } from '@squareup/dex-ui-shared-base';
import { FeatureDetectionContextProvider } from '@squareup/dex-ui-shared-feature-detection';
import { MarketToasterProvider } from '@squareup/dex-ui-shared-market';
import { UserInteractionPublisher } from '@squareup/dex-ui-user-interaction-publisher';
import {
  publishSystemError,
  publishUnhandledError,
} from '@squareup/dex-utils-application-behavior-events';
import {
  convertToEnvironmentUrl,
  getEnvironmentName,
  getNextPublicNameOrDefault,
} from '@squareup/dex-utils-environment';
import { publishActionableError } from '@squareup/dex-utils-error';
import { FeatureDetectionClient } from '@squareup/dex-utils-shared-feature-detection';
import { entityUrlTransformer } from '@squareup/dex-utils-shared-markdown-entity-url';
import { parsedUrlQueryToObject } from '@squareup/dex-utils-shared-routing';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import React, {
  ReactNode,
  StrictMode,
  ReactElement,
  PropsWithChildren,
  FC,
  useMemo,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
// eslint-disable-next-line import/order
import { Provider as ReduxProvider } from 'react-redux';

// Require these to be in app when using new version of next 13
import '@market/web-components/dist/market/market.css';

// eslint-disable-next-line import/no-unassigned-import
import '../scripts/wdyr';

import './global.css';

import { v4 } from 'uuid';

import { configureApp, RootStore } from '../shared/app-config';
import { initializeApp as initializeHybridApp } from '../shared/hybrid-app-init';

import styles from './app.module.css';

type InitialResponse<TUserInfo, TFlags> = {
  error?: StateNodeError | undefined;
} & ServerAppInitResults<TUserInfo, TFlags>;

type NextPageWithLayout<PropType> = NextPage<PropType> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = {
  Component: NextPageWithLayout<unknown>;
} & AppProps<InitialResponse<never, Flags>>;

const ErrorFallback = ({ error }: { error: Error }) => {
  publishSystemError({
    error,
    message: error.message,
  });

  return (
    <PageErrorContents
      returnHref="/reference/square"
      errorType={'unhandled'}
      showSearchButton={false}
      errorMessage={error.message}
    />
  );
};

const ThemedPage = ({ Component }: { Component: ReactNode }) => {
  return <Box className={styles.app}>{Component}</Box>;
};

const LinkWrapper: FC<
  PropsWithChildren<{ trackingId: string; href: string }>
> = ({ children, trackingId, href }) => (
  <Link passHref omitAnchor={true} trackingId={trackingId} href={href}>
    {children}
  </Link>
);

const App = React.memo(
  ({
    store,
    Component,
    featureDetection,
  }: {
    Component: ReactNode;
    store: RootStore;
    featureDetection: FeatureDetectionClient;
  }) => {
    const environment = getEnvironmentName();

    const markdownContextProviderValue = useMemo(() => {
      return {
        LinkComponent: LinkWrapper,
        LinkPreviewer,
        linkRewriter: (href: string) => {
          return convertToEnvironmentUrl(
            entityUrlTransformer(href),
            getEnvironmentName()
          );
        },
        extraComponents: [],
        extraNodes: [],
      };
    }, []);

    return (
      <>
        <Head>
          <title>{getNextPublicNameOrDefault()}</title>
          <meta
            className="swiftype"
            name="page_type"
            data-type="enum"
            content="techref"
          />
          {environment === 'development' ? (
            <link rel="shortcut icon" href="/dev-favicon.png" key="icon" />
          ) : (
            <link rel="shortcut icon" href="/favicon.ico" key="icon" />
          )}
        </Head>
        <StrictMode>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <UIProvider />
            <ReduxProvider store={store}>
              <UserInteractionPublisher>
                <MarkdownContextProvider value={markdownContextProviderValue}>
                  <FeatureDetectionContextProvider
                    value={{ client: featureDetection }}
                  >
                    <MarketToasterProvider>
                      <ScrollDepthReporter entityIdentifier="documentation">
                        <ThemedPage Component={Component} />
                      </ScrollDepthReporter>
                    </MarketToasterProvider>
                  </FeatureDetectionContextProvider>
                </MarkdownContextProvider>
              </UserInteractionPublisher>
            </ReduxProvider>
          </ErrorBoundary>
        </StrictMode>
      </>
    );
  }
);

App.displayName = 'App';

/**
 * The application conmponent used across all pages of the reference.
 * The responsibilty of this component is to add root providers, error handling etc.
 */
function CustomApp({
  pageProps: serverInitialResponse,
  Component,
  router,
}: AppPropsWithLayout) {
  const {
    error: serverError,
    data: serverData,
    requestId: serverRequestId,
  } = serverInitialResponse;

  // Not all pages use SquareVersionPage so if there isnt a request ID create one.
  const requestId = serverRequestId || v4();

  const {
    error: configError,
    isLoading: configIsLoading,
    store,
    featureDetection,
  } = configureApp({ requestId }, serverData);

  // Config error: There is no infra here.
  if (configError || configIsLoading) {
    // TODO: Redirect to standard error page with error meta.
    return null;
  }

  // NOTE: Server should redirect based on its own errors so do not redirect.
  publishActionableError(serverError);

  initializeHybridApp(
    {
      featureDetection,
      requestId,
      searchParams: parsedUrlQueryToObject(router.query),
    },
    serverData
  ).catch((error) => {
    // TODO: There is infra here. Set error state.
    publishUnhandledError(error);
  });

  // I understand that the useMemo should be first, but this is a temporary
  // workaround to ensure the app doesn't re-render for hash links
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ComponentWithProps = useMemo(() => {
    return (
      <Component
        {...{
          ...(serverData && serverData.doc),
          devGuides: serverData?.devGuides,
          store,
        }}
      ></Component>
    );
  }, [Component, serverData, store]);

  return (
    <App
      store={store}
      featureDetection={featureDetection}
      Component={ComponentWithProps}
    />
  );
}

export default CustomApp;
