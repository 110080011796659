'use client';

import React, { FC, useRef, ReactNode, useMemo } from 'react';

import { MarketToaster } from '../MarketPassthrough';

import { ToasterContext } from './ToasterContext';

interface MarketToasterProviderProps {
  children: ReactNode;
}

export const MarketToasterProvider: FC<MarketToasterProviderProps> = ({
  children,
}) => {
  const marketToasterRef = useRef<globalThis.HTMLMarketToasterElement>(null);

  const toasterContext = useMemo(
    () => ({
      // Do not read ref during render cycle, instead expose a getter
      getToaster: () => marketToasterRef.current,
    }),
    []
  );

  return (
    <>
      <MarketToaster ref={marketToasterRef} />
      <ToasterContext.Provider value={toasterContext}>
        {children}
      </ToasterContext.Provider>
    </>
  );
};
