import {
  EventClient,
  PageNavigationType,
  WebAppEventClientConfiguration,
} from '@square/web-app-event-client';
import { ApplicationEventTrackingQueue } from '@squareup/dex-utils-application-behavior-event-tracking';
import { ApplicationEventConsumerSubscribe } from '@squareup/dex-utils-application-behavior-events';
import {
  isServerContext,
  getEnvironmentName,
  isDevelopmentEnvironment,
} from '@squareup/dex-utils-environment';

import { getCategory, getLabel, getName } from './consumer-helpers';

interface WebApplicationEventConsumerOptions {
  applicationName: string;
  enableLinkClickListener: boolean;
  gaTrackingId: string;
}

const User = 'user';
const State = 'state';

function WebApplicationEventConsumer(
  subscribe: ApplicationEventConsumerSubscribe,
  {
    applicationName,
    enableLinkClickListener,
    gaTrackingId,
  }: WebApplicationEventConsumerOptions
) {
  const environment = getEnvironmentName();

  // Configure
  if (isServerContext()) {
    return;
  }

  // TODO add flags
  const options: WebAppEventClientConfiguration = {
    environment,
    applicationName,
    gtag: {
      enable: !isDevelopmentEnvironment(),
      gaTrackingId,
    },
    flags: {
      web_app_log_to_cdp: true,
      web_app_log_to_es2: true,
    },
    enableHeartbeat: true,
    enableLinkClickListener,
  };

  const webAppEventClient = EventClient;
  // Avoid double initializing
  if (!(webAppEventClient as unknown as { initialized: boolean }).initialized) {
    webAppEventClient.initialize(options);
  }

  const queue = new ApplicationEventTrackingQueue(User);

  subscribe(User, (userEvent) => {
    /**
     * Tracking types of user interactions
     * - navigations
     * - interactions on interactive elements
     */
    const { type, event } = userEvent;
    const { action, extra } = event;

    // TODO: where do we log this request id? Ideally this would be a column to allow us to partition client app sessions.
    // console.log(`requestId: ${requestId}`);

    if (action === 'navigate') {
      queue.enqueue(() =>
        webAppEventClient.trackPageNavigation({
          type: extra as PageNavigationType,
        })
      );
    } else {
      // All other actions on interactable elements

      // We only log the name for page action events. Page nav
      // events record the name themselves as the pathname
      const name = getName(event);
      const category = getCategory(type, event);
      const label = getLabel(event) || '';

      queue.enqueue(() =>
        webAppEventClient.trackPageAction(name, {
          category,
          label,
          nonInteraction: false, // As it stands all user interactions are interactive actions. If we need noninteractive its a diff type of action.
        })
      );
    }
  });

  subscribe(State, (stateEvent) => {
    const { type, event } = stateEvent;
    const name = getName(event);
    const label = getLabel(event) || '';

    queue.enqueue(() =>
      webAppEventClient.trackPageAction(name, {
        category: `[${type}]`,
        label,
        nonInteraction: false,
      })
    );
  });
}

export { WebApplicationEventConsumer };
