import { NullableStateNodeError } from '@squareup/dex-types-data-state';

const isError = (error: unknown): error is Error => {
  return Object.prototype.hasOwnProperty.call(error, 'message');
};

const stateErrorToString = (error: NullableStateNodeError) => {
  return `Error occurred: ${
    error ? JSON.stringify(error) : 'Error undefined'
  } `;
};

const extractErrorMessage = (error: unknown): string | undefined => {
  if (isError(error) && typeof error['message'] === 'string') {
    return error.message;
  } else if (typeof error === 'string') {
    return error;
  } else {
    return undefined;
  }
};

/**
 * The common formatter for an error.
 */
const formatErrorToString = (error: unknown, context: string) => {
  return `Error occurred in ${context}. error:${extractErrorMessage(error)}.`;
};

export { stateErrorToString, extractErrorMessage, formatErrorToString };
