/* eslint-disable no-console */
import { ApplicationEventConsumerSubscribe } from '@squareup/dex-utils-application-behavior-events';

import { getCategory, getLabel, getName } from './consumer-helpers';

const System = 'system';
const User = 'user';
const State = 'state';
const Task = 'task';

function ConsoleApplicationEventConsumer(
  subscribe: ApplicationEventConsumerSubscribe
) {
  const tasks = new Map();

  subscribe(System, (event) => {
    const {
      subType,
      event: { message, error, context },
      requestId,
    } = event;

    if (subType === 'error') {
      console.error(
        `%c|${System}| |${subType}| |${message}| |${error}| |requestId:${requestId}|`,
        'color: red;'
      );
    } else {
      console.log(
        `%c|${System}| |${subType}| |${message}| |${
          context ? JSON.stringify(context, null, 2) : {}
        }| |requestId:${requestId}|`,
        'color: black;'
      );
    }
  });

  subscribe(User, (userEvent) => {
    const { type, event, requestId } = userEvent;
    const { action, extra } = event;

    if (action === 'navigate') {
      console.log(
        `%c|${User}| |action:${action}| |type:${extra}| |requestId:${requestId}|`,
        'color: brown;'
      );
    } else {
      const name = getName(event);
      const category = getCategory(type, event);
      const label = getLabel(event) || '';

      console.log(
        `%c|${User}| |action:${action}| |name:${name}| |category:${category}| |label:${label}| |requestId:${requestId}|`,
        'color: blue;'
      );
    }
  });

  subscribe(State, (stateEvent) => {
    const { type, event, requestId } = stateEvent;
    const name = getName(event);
    const label = getLabel(event) || '';

    console.log(
      `%c|${State}| |name:${name}| |category:${type}| |label:${label}| |requestId:${requestId}|`,
      'color: brown;'
    );
  });

  subscribe(Task, (taskEvent) => {
    const { type, event, requestId } = taskEvent;
    const name = getName(event);
    const category = getCategory(type, event);

    const taskKey = `|name:${name}| |category:${type}| |requestId:${requestId}|`;
    if (event.status === 'start') {
      if (tasks.has(taskKey)) {
        console.error(
          `Received task start event for a task already started:${taskKey}`
        );
      } else {
        tasks.set(taskKey, Date.now());
      }
    } else {
      if (!tasks.has(taskKey)) {
        console.error(
          `Received task end event for a task not started:${taskKey}`
        );
      } else {
        const started = tasks.get(taskKey);
        const elapsed = Date.now() - started;

        console.log(
          `%c|${Task}| |name:${name}| |category:${category}| |elapsed:${elapsed}(ms)| |requestId:${requestId}|`,
          'color: green;'
        );

        tasks.delete(taskKey);
      }
    }
  });
}

export { ConsoleApplicationEventConsumer };
