import { UrlObject } from 'url';

import { FocusProps, NullableClassName } from '@squareup/dex-types-shared-ui';
import { TrackedProps } from '@squareup/dex-types-shared-utils';
import { useSearchParamsObject } from '@squareup/dex-utils-shared-routing-hooks';
import clsx from 'clsx';
import NextLink from 'next/link';
import React, { MouseEvent, ReactNode } from 'react';

import { focusRingStyles } from '../FocusRing';

import { useLinkContext } from './LinkContext';
import { isRelativeUrl, persistSearchParams } from './link-utils';

NextLink.displayName = 'NextLink';

type LinkProps = {
  children?: ReactNode;
  onClick?: ((event: MouseEvent) => void) | undefined;
  /**
   * When true will not auto wrap the children in an anchor element.
   * This allows the consumer to use whatever child they wish
   * however the best practice is to use an anchor.
   */
  omitAnchor?: boolean;
  /**
   * The test ID to be added to the first child component of the link.
   */
  testId?: string | undefined;

  /**
   * Disable the text decoration on a link
   */
  disableTextDecoration?: boolean | undefined;
  /**
   * The href to link to. When not supplied will render as a span.
   */
  href?: React.ComponentProps<typeof NextLink>['href'] | undefined;

  /**
   * Boolean from the NextLink props
   */
  passHref?: React.ComponentProps<typeof NextLink>['passHref'] | undefined;
} & Omit<React.ComponentProps<typeof NextLink>, 'href' | 'legacyBehavior'> &
  TrackedProps &
  NullableClassName &
  FocusProps;

function Link({
  href,
  className,
  children,
  onClick,
  omitAnchor,
  trackingId,
  trackingExtra,
  testId,
  target = '_self',
  omitFocus,
  focusBorderRadius,
  omitFocusSpacing,
  omitFocusStyling,
  disableTextDecoration = false,
  passHref,
  slot,
}: LinkProps) {
  const { persistedSearchParams, baseHref } = useLinkContext();
  const currentSearchParams = useSearchParamsObject();

  let finalHref = persistSearchParams(
    href,
    persistedSearchParams,
    currentSearchParams
  );

  if (baseHref && isRelativeUrl(finalHref)) {
    finalHref = (href as UrlObject).pathname
      ? `${baseHref}/${(href as UrlObject).pathname}`
      : `${baseHref}/${finalHref}`;
  }

  // NOTE we are not currently leveraging the Market Link due to issues they have with SSR. Once that is resolved we will move back.
  const anchoredChild = (
    <a
      tabIndex={omitFocus ? -1 : undefined}
      data-testid={testId}
      data-tracking-id={trackingId}
      data-tracking-extra={trackingExtra}
      onClick={onClick}
      target={target}
      className={clsx(
        !omitFocus && !omitFocusStyling && focusRingStyles['focus-ring'],
        focusBorderRadius && focusRingStyles[`focus-ring-${focusBorderRadius}`],
        omitFocusSpacing && focusRingStyles['focus-ring-omit-spacing'],
        disableTextDecoration && focusRingStyles['no-text-decor'],
        className
      )}
      slot={slot}
    >
      {children}
    </a>
  );

  const child = omitAnchor ? children : anchoredChild;

  return finalHref ? (
    /**
     * Why is legacyBehavior present? This was a breaking change in Next.js 13.
     * When legacyBehavior is not present(default) next link will create an anchor around the child provided
     * and error out if the child is an anchor.
     * We never want this as we wish to use Market links which resolve to anchors as our default.
     * When omitAnchor === true we let the consumer create their own anchor child or whatever they wish to use
     * without error.
     **/
    <NextLink legacyBehavior href={finalHref} passHref={passHref}>
      {child}
    </NextLink>
  ) : (
    <span
      data-testid={testId}
      data-interactable={true} // Mark as the interactive item to opt into tracking
      data-tracking-id={trackingId}
      data-tracking-extra={trackingExtra}
      className={className}
    >
      {child}
    </span>
  );
}

export { Link, type LinkProps };
