interface Event {
  type: string;
}

type NarrowByType<T, N> = T extends { type: N } ? T : never;

export type EventUnSubscriber = () => void;

export class PubSub {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private eventSubscriberMap: Map<string, ((_event: any) => unknown)[]> =
    new Map();

  /**
   * Subscribes to an event of the given type.
   * Upon receipt of any event of that type the given callback will be executed.
   * @param eventType - The event type to subscribe by.
   * @param callback - The callback to execute upon event.
   * @returns - An unsubscribe function to be executed to remove the subscription.
   */
  public subscribe<TEvent extends Event, TType extends TEvent['type']>(
    eventType: TType,
    callback: (_event: NarrowByType<TEvent, TType>) => unknown
  ): EventUnSubscriber {
    if (!this.eventSubscriberMap.has(eventType)) {
      this.eventSubscriberMap.set(eventType, []);
    }

    const subs = this.eventSubscriberMap.get(eventType);
    const existingSubIndex = subs?.indexOf(callback);

    if (existingSubIndex && existingSubIndex === -1) {
      this.eventSubscriberMap.set(eventType, [...(subs || []), callback]);
    }

    return () => {
      // Clear out the subscription.
      const subs = this.eventSubscriberMap.get(eventType);
      if (subs) {
        const filteredSubs = subs.filter(
          (subCallback) => callback !== subCallback
        );
        this.eventSubscriberMap.set(eventType, filteredSubs);
      }
    };
  }

  /**
   * Publishes the event by its type to all subscribers.
   */
  public publish<TEvent extends Event>(event: TEvent) {
    const subs = this.eventSubscriberMap.get(event.type);
    if (!subs || subs.length === 0) {
      // No one cares  ¯\_(ツ)_/¯
      return;
    }

    // Clone the event as to not allow mutation across subscribers.
    subs.forEach((sub) => sub(JSON.parse(JSON.stringify(event))));
  }

  /**
   * Clears out the state of the subscriptions held.
   */
  public clear() {
    this.eventSubscriberMap.clear();
  }
}
