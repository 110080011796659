import {
  TrackingProps,
  ViewEvent,
} from '@squareup/dex-types-shared-martech-types';
import { ApplicationEventTrackingQueue } from '@squareup/dex-utils-application-behavior-event-tracking';
import {
  ApplicationEventConsumerSubscribe,
  publishUnhandledError,
} from '@squareup/dex-utils-application-behavior-events';
import {
  getWindow,
  isProductionEnvironment,
} from '@squareup/dex-utils-environment';
import { extractErrorMessage } from '@squareup/dex-utils-error-formatting';
import { ScriptLoader } from '@squareup/dex-utils-scripts';

const loadMartechScript = () => {
  const scriptLoader = new ScriptLoader();
  const scriptSrc = process.env.NEXT_PUBLIC_MARTECH_SCRIPT_SRC;

  if (!scriptSrc) {
    return Promise.reject(
      new Error('NEXT_PUBLIC_MARTECH_SCRIPT_SRC is not defined')
    );
  }

  return scriptLoader.load(window, (script: HTMLScriptElement) => {
    script.setAttribute('src', scriptSrc);
    // Don't block script parsing for martech, since events queue
    script.setAttribute('defer', 'true');
  });
};

interface MartechContextOpts {
  countryCode?: string | undefined;
  userToken?: string | undefined;
}

const configureMartech = ({ countryCode, userToken }: MartechContextOpts) => {
  if (!window.martech) {
    throw new Error('window.martech is not defined');
  }

  if (countryCode || userToken) {
    window.martech.addDataLayerContext({
      country_code: countryCode || '',
      user_token: userToken || '',
    });
  }
};

const setupMartech = async (contextOpts: MartechContextOpts) => {
  try {
    await loadMartechScript();
    configureMartech(contextOpts);
  } catch (error) {
    publishUnhandledError(
      new Error(`martech setup failed: ${extractErrorMessage(error)}`)
    );
  }
};

function MartechApplicationEventConsumer(
  subscribe: ApplicationEventConsumerSubscribe,
  contextOpts: MartechContextOpts
) {
  // Martech uses iframes thus requires a window
  const window = getWindow();

  if (!window) {
    return;
  }

  // Martech should only run in production
  if (!isProductionEnvironment()) {
    return;
  }

  const setupMartechPromise = setupMartech(contextOpts);

  const eventQueue = new ApplicationEventTrackingQueue(
    'user',
    setupMartechPromise
  );

  const trackInQueue = (props: TrackingProps) => {
    eventQueue.enqueue(async () => {
      try {
        await window.martech?.track(props);
      } catch (error) {
        publishUnhandledError(
          new Error(`martech track failed: ${extractErrorMessage(error)}`)
        );
      }
    });
  };

  subscribe('user', ({ event }) => {
    switch (event.action) {
      case 'navigate': {
        trackInQueue({
          event: ViewEvent.PageLoad,
        });

        break;
      }

      default:
        break;
    }
  });

  /**
   * TODO: Track app creations once app creation feature is built
   * This behavior is specific to devs-console, so should probably be passed in
   * to MartechApplicationEventConsumer.
  subscribe('state', ({ event }) => {
    switch (event.action) {
      case 'create-application': {
        trackInQueue({
          event: ActionEvent.AppCreation,
          action: TrackingAction.AppCreation,
          variant: TrackingVariant.developers,
        });

        break;
      }

      default:
        break;
    }
  });
  */
}

export { MartechApplicationEventConsumer };
