import { isServerContext } from '@squareup/dex-utils-environment';
import Cookies from 'js-cookie';

const CSRF_COOKIE_NAME = '_js_csrf';

// TODO: Implement a better mechanism for CSRF once auth is in place
export const getCsrfFromCookie = () => {
  if (isServerContext()) {
    return null;
  }

  return Cookies.get(CSRF_COOKIE_NAME);
};
