import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryFactory } from '@squareup/dex-data-shared-base-api';
import { getNextPublicBaseURL } from '@squareup/dex-utils-environment';

import { appSubmissionsEndpointsFactory } from './app-submissions-endpoints';
import { DEVELOPER_API_REDUCER_PATH } from './developer-api-constants';
import { DeveloperApiTagTypes } from './developer-api-tag-types';
import { developerContactsEndpointFactory } from './developer-contacts-endpoints';
import { developerDataEndpointFactory } from './developer-data-endpoints';
import { devsContentEndpointFactory } from './devs-content-endpoints';
import { dexTechFeEndpointFactory } from './dex-tech-fe-endpoints';
import { portalEndpointsFactory } from './portal-endpoints';

const baseUrl = getNextPublicBaseURL();

const createDeveloperApi = () => {
  const developerApi = createApi({
    baseQuery: baseQueryFactory({ baseUrl }),
    endpoints: (_builder) => ({}),
    reducerPath: DEVELOPER_API_REDUCER_PATH,
    tagTypes: Object.values(DeveloperApiTagTypes),
  });

  // Inject the endpoints for each service here
  const injectedApi = developerApi
    .injectEndpoints({
      endpoints: (builder) => portalEndpointsFactory(builder),
    })
    .injectEndpoints({
      endpoints: (builder) => devsContentEndpointFactory(builder),
    })
    .injectEndpoints({
      endpoints: (builder) => developerDataEndpointFactory(builder),
    })
    .injectEndpoints({
      endpoints: (builder) => dexTechFeEndpointFactory(builder),
    })
    .injectEndpoints({
      endpoints: (builder) => appSubmissionsEndpointsFactory(builder),
    })
    .injectEndpoints({
      endpoints: (builder) => developerContactsEndpointFactory(builder),
    });

  // Store config
  const developerApiStoreConfig = {
    middleware: developerApi.middleware,
    reducer: developerApi.reducer,
    reducerPath: developerApi.reducerPath,
  };

  return { developerApi: injectedApi, developerApiStoreConfig };
};

let _developerApi: ReturnType<typeof createDeveloperApi> | undefined;

const getApi = () => {
  if (_developerApi) {
    return _developerApi;
  }

  _developerApi = createDeveloperApi();
  return _developerApi;
};

const getDeveloperApi = () => {
  return getApi().developerApi;
};

const getDeveloperApiStoreConfig = () => {
  return getApi().developerApiStoreConfig;
};

export { getDeveloperApi, getDeveloperApiStoreConfig };
