import {
  UserEvent,
  StateEvent,
  TaskEvent,
} from '@squareup/dex-utils-application-behavior-events';

/**
 * Take a user event and returns a string in the format of '[Action]-[On]-[ID]'
 */
function getName(event: UserEvent | StateEvent | TaskEvent): string {
  const { action, onType, onIdentifier } = event;
  return `[${action}]-[${onType || 'unset'}]-[${onIdentifier}]`;
}

/**
 * Take a user event and returns a string in the format of '[Type]-[Context]'
 */
function getCategory(type: string, event: UserEvent | TaskEvent): string {
  const { context } = event;
  return `[${type}]-[${context}]`;
}

/**
 * Take a user event and returns a string in the format of '[Status]-[Extra]'
 */
function getLabel(
  event: UserEvent | StateEvent | TaskEvent
): string | undefined {
  const { extra } = event;
  let status = undefined;

  if ('status' in event) {
    status = event.status;
  }

  return status || extra ? `[${status || ''}]-[${extra || ''}]` : undefined;
}

export { getCategory, getLabel, getName };
