import { publishSystemError } from '@squareup/dex-utils-application-behavior-events';
import {
  createFailedDecision,
  Decision,
} from '@squareup/dex-utils-shared-feature-detection';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { FeatureDetectionContextInstance } from './feature-detection-context';

type UIFeatureDecision = {
  decision: Decision;
  isClientReady: boolean;
};

const useDecision = (featureKey: string): UIFeatureDecision => {
  const { client } = useContext(FeatureDetectionContextInstance);

  const clientMissingErrorDecision = useMemo(() => {
    return createFailedDecision(
      featureKey,
      'FeatureDetectionProvider not configured properly yet.'
    );
  }, [featureKey]);

  const isClientReady = useCallback(
    () => (client ? client.isReady() : false),
    [client]
  );

  // TODO: The decision should be a stable object unless something has changed [featureKey, value].
  const getDecision = useCallback(() => {
    const decision = client
      ? client.decide(featureKey)
      : clientMissingErrorDecision;
    return {
      decision,
      isClientReady: isClientReady(),
    };
  }, [client, featureKey, clientMissingErrorDecision, isClientReady]);

  const [state, setState] = useState<UIFeatureDecision>(() => getDecision());

  // Until we move to async server components this will only apply on client side rendering.
  // For now its expected that the client would be populated in the SSR hooks on the server
  // So our first render pass should have the data.
  useEffect(() => {
    const unsub = client?.onUpdate(() => {
      setState(getDecision());
    });
    return () => {
      unsub && unsub();
    };
  }, [client, getDecision]);

  if (!client) {
    publishSystemError(
      new Error(
        `Unable to use decision ${featureKey}. client prop must be supplied via a parent <FeatureDetectionProvider>`
      )
    );

    // Only set an error reason if we dont already have one. This prevents looping an overwriting the existing errors.
    if (state.decision.reasons.length === 0) {
      setState({
        decision: clientMissingErrorDecision,
        isClientReady: isClientReady(),
      });
    }
  }

  return state;
};

export { useDecision, type UIFeatureDecision };
