import { publishSystemError } from '@squareup/dex-utils-application-behavior-events';
import { isStringTrue } from '@squareup/dex-utils-text';

/**
 * Reads the environment name from config and returns that name. Throws if not found or invalid.
 */
function getEnvironmentName(): globalThis.EnvironmentName {
  const environmentName =
    typeof process !== 'undefined'
      ? process.env.NEXT_PUBLIC_ENVIRONMENT
      : undefined;
  const possibleEnvironments = ['production', 'staging', 'development'];
  const isValidEnvSet =
    environmentName !== undefined &&
    possibleEnvironments.includes(environmentName) !== undefined;

  if (!isValidEnvSet) {
    const message = `Invalid environment variable NEXT_PUBLIC_ENVIRONMENT set in config. Value: ${environmentName}. Verify value set is one of [${possibleEnvironments.toString()}]`;
    publishSystemError({ message });
    throw new Error(message);
  }

  return environmentName;
}

/**
 * Returns true if the current js context is a server context.
 */
function isServerContext(): boolean {
  return typeof window === 'undefined';
}

/**
 * Checks to see if this is the development environment
 * @returns true if development, false otherwise
 */
function isDevelopmentEnvironment(): boolean {
  return getEnvironmentName() === 'development';
}

/**
 * Checks to see if this is the staging environment
 * @returns true if staging, false otherwise
 */
function isStagingEnvironment(): boolean {
  return getEnvironmentName() === 'staging';
}

/**
 * Checks to see if this is the production environment
 * @returns true if production, false otherwise
 */
function isProductionEnvironment(): boolean {
  return getEnvironmentName() === 'production';
}

/**
 * Returns true if the current context at which the code is running is consumed by public users.
 * This can be used in cases where we need to make decisions on lighting up infra such as tracking etc.
 */
function isPublicContext(): boolean {
  return isStringTrue(process.env.NEXT_PUBLIC_IS_PUBLIC_CONTEXT);
}

/**
 * Returns true if the current context at which the code is running is consumed by public users is is a production build.
 */
function isProductionPublicContext(): boolean {
  return isStringTrue(process.env.NEXT_PUBLIC_IS_PUBLIC_CONTEXT);
}

function getWindow(): globalThis.Window | undefined {
  if (isServerContext()) {
    return undefined;
  }

  return window;
}

export {
  getEnvironmentName,
  getWindow,
  isDevelopmentEnvironment,
  isProductionEnvironment,
  isProductionPublicContext,
  isPublicContext,
  isServerContext,
  isStagingEnvironment,
};
