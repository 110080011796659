import {
  BaseEndpointBuilder,
  endpointBuilder,
} from '@squareup/dex-data-shared-base-api';
import { AccessToken } from '@squareup/dex-types-shared-access-token';
import {
  CreateApiKeyBodyInput,
  CreateApiKeyResponse,
  RevokeApiKeyResponse,
  RevokeApiKeyInput,
  ApiKey,
} from '@squareup/dex-types-shared-api-keys';
import { ApiVersion } from '@squareup/dex-types-shared-api-versions';
import { ApplicationType } from '@squareup/dex-types-shared-application-type';
import { HTTP_METHOD } from '@squareup/dex-types-shared-http';
import { OAuthApplication } from '@squareup/dex-types-shared-oauth-application';
import {
  OAuthClient,
  OAuthClientInput,
  OAuthApplicationSecret,
} from '@squareup/dex-types-shared-oauth-client';
import { OauthPermission } from '@squareup/dex-types-shared-oauth-permissions';
import { PortalUser } from '@squareup/dex-types-shared-portal-user';
import {
  CreateSandboxTestAccountInput,
  SandboxTestAccount,
  SandboxAuthorization,
} from '@squareup/dex-types-shared-sandbox-test-account';
import { ApplicationDetails } from '@squareup/dex-types-shared-square-application-details';

import { DEVELOPER_API_REDUCER_PATH } from './developer-api-constants';
import { DeveloperApiTagTypes } from './developer-api-tag-types';

const PORTAL_API_URL = '/api/portal';

const sandboxModeHeaders = (applicationType: ApplicationType): HeadersInit => {
  const sandboxModeHeaderName = 'sandbox-mode';
  const sandboxModeHeaderValue =
    applicationType === ApplicationType.Sandbox ? 'true' : 'false';

  return {
    [sandboxModeHeaderName]: sandboxModeHeaderValue,
  };
};

type WithApplicationType<T> = T & { applicationType: ApplicationType };

const transformAuthorizationPermissions = (response: {
  'sandbox-authorizations':
    | (Omit<SandboxAuthorization, 'permissions'> & {
        permissions: string;
      })[]
    | null;
  errors: unknown;
}) => {
  const sandboxAuthorizations = response['sandbox-authorizations'] ?? [];
  const transformedSandboxAuthorizations =
    sandboxAuthorizations.map<SandboxAuthorization>(
      ({ permissions, ...rest }) => ({
        ...rest,
        /**
         * Transform the comma-separated string representation of
         * `permissions` used by the API into an array of
         * `OAuthPermission`s for ease of use.
         */
        permissions:
          typeof permissions === 'string'
            ? permissions
                .split(',')
                // Remove any erroneously empty entries
                .filter((permission) => permission.length > 0)
            : [],
      })
    );

  return {
    ...response,
    'sandbox-authorizations': transformedSandboxAuthorizations,
  };
};

const portalEndpointsFactory = (
  builder: BaseEndpointBuilder<typeof DEVELOPER_API_REDUCER_PATH>
) => ({
  /**
   * Fetches all app details.
   */
  getAppDetails: builder.query<
    { 'applications-details': ApplicationDetails[] },
    ApplicationType
  >({
    query: (applicationType: ApplicationType) => {
      const additionalHeaders: HeadersInit =
        sandboxModeHeaders(applicationType);

      return {
        /**
         * TODO: Stop relying on this bogus header for caching separation.
         *
         * In the long-run, this should instead be handled on the service side
         * by either:
         * A) Migrating endpoints to accept a `sandbox` query parameter and
         *    deprecating the `Sandbox-Mode` header
         * or
         * B) By accounting for the sandboxness in response caching logic
         *    through `vary` or `etag` headers
         *
         * The query parameter below is a hacky way to allow the browser to
         * distinguish between sandbox and production when caching responses.
         * This was necessary to avoid cache pollution when switching between
         * connect-web and devs-console.
         */
        path: `${PORTAL_API_URL}/oauth-clients/details?sandbox=${
          applicationType === ApplicationType.Sandbox
        }`,
        additionalHeaders,
      };
    },
    transformResponse: (
      response: { 'applications-details': ApplicationDetails[] },
      _meta: unknown,
      arg: ApplicationType
    ) => {
      if (arg === ApplicationType.Sandbox) {
        const sandboxApplicationDetails = response['applications-details'].map(
          (appDetail: ApplicationDetails) => ({
            ...appDetail,
            sandbox: true,
          })
        );

        return {
          'applications-details': sandboxApplicationDetails,
        };
      }
      return response;
    },
    providesTags: [DeveloperApiTagTypes.ApplicationDetail],
  }),

  getOauthClients: builder.query<
    { 'oauth-clients': OAuthClient[] },
    ApplicationType
  >({
    query: (applicationType: ApplicationType) => {
      const additionalHeaders: HeadersInit =
        sandboxModeHeaders(applicationType);

      return {
        path: `${PORTAL_API_URL}/oauth-clients`,
        additionalHeaders,
      };
    },
    providesTags: [DeveloperApiTagTypes.OAuthClient],
  }),

  /*
   * Updates OAuthClient
   */
  updateOAuthClient: builder.mutation<
    OAuthClient,
    WithApplicationType<OAuthClientInput>
  >({
    query: ({ client_id, applicationType, ...rest }) => {
      const additionalHeaders: HeadersInit =
        sandboxModeHeaders(applicationType);
      return {
        path: `${PORTAL_API_URL}/oauth-clients/${client_id}`,
        method: HTTP_METHOD.PUT,
        additionalHeaders: {
          ...additionalHeaders,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'oauth-client': {
            ...rest,
          },
        }),
      };
    },
    invalidatesTags: [DeveloperApiTagTypes.OAuthClient],
  }),

  /**
   * Fetches all access tokens.
   */
  getAccessTokens: builder.query<
    { 'access-tokens': AccessToken[] },
    ApplicationType
  >({
    query: (applicationType: ApplicationType) => {
      const additionalHeaders: HeadersInit =
        sandboxModeHeaders(applicationType);

      return {
        path: `${PORTAL_API_URL}/access-tokens`,
        additionalHeaders,
      };
    },
    providesTags: [DeveloperApiTagTypes.AccessToken],
  }),

  /**
   * Fetches all api versions.
   */
  getApiVersions: builder.query<{ api_versions: ApiVersion[] }, undefined>({
    query: () => {
      return {
        path: `${PORTAL_API_URL}/api-versions`,
      };
    },
    providesTags: [DeveloperApiTagTypes.ApiVersions],
  }),

  /**
   * Fetches the portal user.
   *
   * Sandbox should not be needed on this endpoint, so the sandbox-mode header is not included.
   */
  getPortalUser: builder.query<{ user: PortalUser }, undefined>({
    query: () => ({
      path: `${PORTAL_API_URL}/user`,
    }),
    providesTags: [DeveloperApiTagTypes.PortalUser],
  }),

  /**
   * Accepts the terms of service, updating the portal user.
   *
   * Sandbox should not be needed on this endpoint, so the sandbox-mode header is not included.
   *
   * Historical context:
   * Originally there was one endpoint that was in charge of fetching user,
   * app, and access token information: /users. In order to gain some control
   * over granularity of permissions, this endpoint was split into 4 endpoints:
   *   /user
   *   /details
   *   /oauth-clients
   *   /access-tokens
   *
   * RBAC did not need a path other than PUT /users for performing updates, so
   * that endpoint is used here.
   */
  acceptTermsOfService: builder.mutation<
    { user: PortalUser },
    { merchantId: string }
  >({
    query: ({ merchantId }) => ({
      path: `${PORTAL_API_URL}/users/${merchantId}`,
      method: HTTP_METHOD.PUT,
      additionalHeaders: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user: { tos_accept: true } }),
    }),
    invalidatesTags: [DeveloperApiTagTypes.PortalUser],
  }),

  /**
   * Updates application details
   */
  updateAppDetails: builder.mutation<ApplicationDetails, ApplicationDetails>({
    query: (appDetails) => {
      return {
        path: `${PORTAL_API_URL}/oauth-clients/${appDetails.id}/details`,
        method: HTTP_METHOD.PUT,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'application-details': appDetails,
        }),
      };
    },
    invalidatesTags: [DeveloperApiTagTypes.ApplicationDetail],
  }),

  /**
   * Creates an application
   */
  createApplication: builder.mutation<
    { oauth_application: OAuthApplication; errors: unknown },
    Partial<ApplicationDetails>
  >({
    query: (appDetails) => {
      return {
        path: `${PORTAL_API_URL}/oauth-applications`,
        method: HTTP_METHOD.POST,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'oauth-client': appDetails }),
      };
    },
    invalidatesTags: [
      DeveloperApiTagTypes.ApplicationDetail,
      DeveloperApiTagTypes.AppSubmission,
    ],
  }),

  getApplications: builder.query<
    {
      oauth_application: { production_client: string; sandbox_client: string };
      errors: unknown[] | null;
    },
    string
  >({
    query: (client_id) => ({
      path: `${PORTAL_API_URL}/sandbox/oauth-applications/${client_id}`,
    }),
    providesTags: [DeveloperApiTagTypes.OAuthApplications],
  }),

  /**
   * Creates an API key
   */
  createApiKey: builder.mutation<CreateApiKeyResponse, CreateApiKeyBodyInput>({
    query: (body) => {
      return {
        path: `${PORTAL_API_URL}/oauth-clients/${body.client_id}/api-keys`,
        method: HTTP_METHOD.POST,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...body }),
      };
    },
    invalidatesTags: [DeveloperApiTagTypes.ApiKey],
  }),

  /**
   * Fetches all API keys.
   */
  getApiKeys: builder.query<{ api_keys: ApiKey[] }, string>({
    query: (client_id) => {
      return {
        path: `${PORTAL_API_URL}/oauth-clients/${client_id}/api-keys`,
      };
    },
    providesTags: [DeveloperApiTagTypes.ApiKey],
  }),

  /**
   * revokes an API key
   */
  revokeApiKey: builder.mutation<RevokeApiKeyResponse, RevokeApiKeyInput>({
    query: ({ api_key_id, client_id }) => {
      return {
        path: `${PORTAL_API_URL}/oauth-clients/${client_id}/api-keys/revoke`,
        method: HTTP_METHOD.POST,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ api_key_id }),
      };
    },
    invalidatesTags: [DeveloperApiTagTypes.ApiKey],
  }),

  /**
   * Fetches oauth permissions.
   */
  getOauthPermissions: builder.query<
    {
      'oauth-permissions': OauthPermission[];
    },
    undefined
  >({
    query: () => ({
      path: `${PORTAL_API_URL}/oauth-permissions`,
    }),
  }),

  /**
   * Creates a sandbox test account (a non-primary merchant).
   */
  createSandboxTestAccount: builder.mutation<
    {
      account: SandboxTestAccount;
      'sandbox-authorizations': SandboxAuthorization[];
      errors: unknown;
    },
    CreateSandboxTestAccountInput
  >({
    query: (input) => {
      /**
       * If `permissions` are present, transform them from an array of
       * `OauthPermission`s into the comma-separated string format required by
       * the API.
       */
      const oauthPermissions = input.permissions
        ? input.permissions
            // Remove any erroneously empty entries
            .filter((permission) => permission.length > 0)
            .join(',')
        : null;

      return {
        path: `${PORTAL_API_URL}/sandbox/merchants`,
        method: HTTP_METHOD.POST,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...input,
          ...(oauthPermissions && {
            permissions: oauthPermissions,
          }),
        }),
      };
    },
    transformResponse: (response: {
      account: SandboxTestAccount;
      'sandbox-authorizations':
        | (Omit<SandboxAuthorization, 'permissions'> & {
            permissions: string;
          })[]
        | null;
      errors: unknown;
    }) => {
      const sandboxAuthorizations = response['sandbox-authorizations'] ?? [];
      const transformedSandboxAuthorizations =
        sandboxAuthorizations.map<SandboxAuthorization>(
          ({ permissions, ...rest }) => ({
            ...rest,
            /**
             * Transform the comma-separated string representation of
             * `permissions` used by the API into an array of
             * `OauthPermission`s for ease of use.
             */
            permissions:
              typeof permissions === 'string'
                ? permissions
                    .split(',')
                    // Remove any erroneously empty entries
                    .filter((permission) => permission.length > 0)
                : [],
          })
        );

      return {
        ...response,
        'sandbox-authorizations': transformedSandboxAuthorizations,
      };
    },
    invalidatesTags: [DeveloperApiTagTypes.SandboxTestAccount],
  }),

  /**
   * replace a legacy PAT
   */
  replaceAccessToken: builder.mutation<AccessToken, { client_id: string }>({
    query: ({ client_id }) => {
      return {
        path: `${PORTAL_API_URL}/oauth-clients/${client_id}/access-tokens`,
        method: HTTP_METHOD.POST,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'access-token': { client_id } }),
      };
    },
    invalidatesTags: [
      DeveloperApiTagTypes.AccessToken,
      DeveloperApiTagTypes.ApiKey,
    ],
  }),

  /**
   * Get sandbox merchants
   */
  getSandboxMerchants: builder.query<
    { accounts: SandboxTestAccount[] },
    undefined
  >({
    query: () => {
      return {
        path: `${PORTAL_API_URL}/sandbox/merchants`,
      };
    },
    providesTags: [DeveloperApiTagTypes.SandboxTestAccount],
  }),

  /**
   * Delete sandbox merchant
   */
  deleteSandboxMerchant: builder.mutation<void, string>({
    query: (sandboxMerchantId) => {
      return {
        path: `${PORTAL_API_URL}/sandbox/merchants/${sandboxMerchantId}`,
        method: HTTP_METHOD.DELETE,
      };
    },
    invalidatesTags: [
      DeveloperApiTagTypes.SandboxTestAccount,
      DeveloperApiTagTypes.SandboxAuthorization,
    ],
  }),

  /**
   * Get sandbox authorizations
   */
  getSandboxAuthorizations: builder.query<
    { 'sandbox-authorizations': SandboxAuthorization[] },
    string
  >({
    query: (sandboxMerchantId) => {
      return {
        path: `${PORTAL_API_URL}/sandbox/authorizations?sandbox_merchant_id=${sandboxMerchantId}`,
      };
    },
    transformResponse: transformAuthorizationPermissions,
    providesTags: [DeveloperApiTagTypes.SandboxAuthorization],
  }),

  /**
   * Get sandbox authorizations by client id
   */
  getSandboxAuthorizationsByClientId: builder.query<
    { 'sandbox-authorizations': SandboxAuthorization[] },
    string
  >({
    query: (sandboxClientId) => {
      return {
        path: `${PORTAL_API_URL}/sandbox/authorizations?sandbox_client_id=${sandboxClientId}`,
      };
    },
    transformResponse: transformAuthorizationPermissions,
    providesTags: [DeveloperApiTagTypes.SandboxAuthorization],
  }),

  /**
   * Create sandbox authorization
   */
  createSandboxAuthorization: builder.mutation<void, SandboxAuthorization>({
    query: (input) => {
      // Transform permissions array into comma-separated string
      const permissions = input.permissions
        ? input.permissions.join(',')
        : null;
      // Send null auth token value instead of empty string
      const authToken = input.authorization_token || null;
      return {
        path: `${PORTAL_API_URL}/sandbox/authorizations`,
        method: HTTP_METHOD.POST,
        body: JSON.stringify({
          'sandbox-authorization': {
            ...input,
            authorization_token: authToken,
            permissions,
          },
        }),
      };
    },
    invalidatesTags: [DeveloperApiTagTypes.SandboxAuthorization],
  }),

  /**
   * Update sandbox authorization
   */
  updateSandboxAuthorization: builder.mutation<void, SandboxAuthorization>({
    query: (sandboxAuthorization) => {
      // Transform permissions array into comma-separated string
      const permissions = sandboxAuthorization.permissions
        ? sandboxAuthorization.permissions.join(',')
        : null;
      return {
        path: `${PORTAL_API_URL}/sandbox/authorizations/${sandboxAuthorization.authorization_token}`,
        method: HTTP_METHOD.PUT,
        body: JSON.stringify({
          'sandbox-authorization': {
            ...sandboxAuthorization,
            permissions,
          },
        }),
      };
    },
    invalidatesTags: [DeveloperApiTagTypes.SandboxAuthorization],
  }),

  /**
   * Delete sandbox authorization
   */
  deleteSandboxAuthorization: builder.mutation<void, string>({
    query: (authorizationToken) => ({
      path: `${PORTAL_API_URL}/sandbox/authorizations/${authorizationToken}`,
      method: HTTP_METHOD.DELETE,
    }),
    invalidatesTags: [DeveloperApiTagTypes.SandboxAuthorization],
  }),

  replaceApplicationSecret: builder.mutation<
    OAuthApplicationSecret,
    WithApplicationType<{ clientId: string }>
  >({
    query: ({ clientId, applicationType }) => {
      const additionalHeaders: HeadersInit =
        sandboxModeHeaders(applicationType);
      return {
        path: `${PORTAL_API_URL}/oauth-clients/${clientId}/secrets`,
        additionalHeaders,
        method: HTTP_METHOD.POST,
      };
    },
    invalidatesTags: [DeveloperApiTagTypes.OAuthClient],
  }),
});

const portalEndpoints = portalEndpointsFactory(endpointBuilder);

export { portalEndpoints, portalEndpointsFactory };
