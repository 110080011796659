import { NarrowByType } from '@squareup/dex-types-shared-utils';
import { EventUnSubscriber } from '@squareup/dex-utils-pub-sub';

type UserActionTypes = 'navigate';

type TaskActionTypes = 'task' | 'render';

type Status = 'success' | 'not-found' | 'error' | 'invalid';

/**
 * The source list of all user interactable item names in the application.
 * Examples include DOM elements
 */
const useInteractableNames = [
  'document',
  'a',
  'button',
  'link',
  'market-button',
  'market-link',
  'market-table-row',
  'market-row',
  'market-input-text',
] as const;
type UserInteractableTypes = typeof useInteractableNames[number];

/**
 * The source list of all measurable task items names in the application.
 * Examples include the ui, util, data
 */
const taskInteractableNames = ['util'] as const;
type TaskInteractableTypes = typeof taskInteractableNames[number];

/**
 * An application event meta that holds common event data.
 */
type ApplicationEventMeta = {
  requestId?: string | undefined;
};

/**
 * A User event supplied by application code.
 */
type UserEvent = {
  /**
   * The type of interaction which took place on the target. (e.g. click, change)
   */
  action: UserActionTypes;
  /**
   * The type of target the interaction was on. (button, div)
   */
  onType: UserInteractableTypes;
  /**
   * A unique identifier of the target among siblings or others of the same interactable type
   */
  onIdentifier: string;
  /**
   * The location or context in the system where the interaction took place. ([application]-[page]-[panel]-[container])
   */
  context: string;
  /**
   * Any extra identifying information.(provided by developer freeform)
   */
  extra?: string | undefined;
};

/**
 * A State event supplied by application code.
 */
type StateEvent = {
  /**
   * The action. e.g. search-request, get-user, create-application, create-account
   */
  action: string;
  /**
   * entity ID
   */
  onIdentifier: string;
  /**
   * The type of target the interaction was on. (store)
   */
  onType?: string;
  /**
   * The status resulting from the interaction.(success, not-found, error, invalid)(provided by developer in some cases but values constrained)
   */
  status?: Status;
  /**
   * Any extra identifying information.(provided by developer freeform)
   */
  extra?: string | undefined;
};

type TaskEvent = {
  /**
   * The type of task which took place. (render, task)
   */
  action: TaskActionTypes;
  /**
   * The type of target the task was on. (app, util, ui))
   */
  onType: TaskInteractableTypes;
  /**
   * A unique identifier of the target among siblings or others of the same interactable type.
   */
  onIdentifier: string;
  /**
   * The status of the task taken place.
   */
  status: 'start' | 'end';
  /**
   * The location or context in the system where the interaction took place.
   */
  context: string;
  /**
   * Any extra identifying information.(provided by developer freeform)
   */
  extra?: string | undefined;
};

/**
 * A System event supplied by application code.
 */
type SystemEvent = {
  message: string;
  error?: unknown | undefined;
  context?: Record<string, string> | undefined;
};

/**
 * An event which captures system behaviors.
 * This is part of the observability or application health category of events.
 */
type AppSystemEvent = {
  type: 'system';
  subType: 'debug' | 'info' | 'warn' | 'error';
  event: SystemEvent;
};

/**
 * An event which captures user behaviors.
 * This is part of the telemetry or business metrics category of events.
 **/
type AppUserEvent = {
  type: 'user';
  event: UserEvent;
};

/**
 * An event which captures state behaviors.
 **/
type AppStateEvent = {
  type: 'state';
  event: StateEvent;
};

/**
 * An event which captures task behaviors.
 **/
type AppTaskEvent = {
  type: 'task';
  event: TaskEvent;
};

/**
 * All possible application event types that may occur.
 */
type ApplicationEvent =
  | AppSystemEvent
  | AppUserEvent
  | AppStateEvent
  | AppTaskEvent;

/**
 * An application event once published to consumers having all meta data attached by the framework.
 */
type PublishedApplicationEvent = ApplicationEvent & ApplicationEventMeta;

/**
 * A contract for all application event consumers to use.
 */
type ApplicationEventConsumerSubscribe = <
  TEvent extends PublishedApplicationEvent,
  TType extends TEvent['type']
>(
  _eventType: TType,
  _callback: (_event: NarrowByType<TEvent, TType>) => unknown
) => EventUnSubscriber;

/**
 * The application behavior events config.
 * This holds values common to config all events.
 */
type ApplicationBehaviorConfiguration = {
  /**
   * The unique id of the request emitting events.
   */
  requestId: string;

  /**
   * When true will not send events to event consumers.
   */
  silent?: boolean;
};

/**
 * All possible types of application events consumers can subscribe to.
 */
type ApplicationEventType = Parameters<ApplicationEventConsumerSubscribe>[0];

export {
  type PublishedApplicationEvent,
  type ApplicationEvent,
  type ApplicationEventConsumerSubscribe,
  type ApplicationBehaviorConfiguration,
  type UserEvent,
  type StateEvent,
  type SystemEvent,
  type ApplicationEventType,
  type UserActionTypes,
  type UserInteractableTypes,
  type TaskEvent,
  useInteractableNames as interactableNames,
};
