import { OneTrustCategory } from '@square/onetrust-compliant-access';
import { ApplicationEventType } from '@squareup/dex-utils-application-behavior-events';

const applicationEventOneTrustCategoryMap: Map<
  ApplicationEventType,
  OneTrustCategory
> = new Map([
  ['system', OneTrustCategory.PERFORMANCE],
  ['user', OneTrustCategory.TARGETING],
]);

export { applicationEventOneTrustCategoryMap };
