import {
  MarkdownComponent,
  MarkdownNode,
} from '@squareup/dex-types-shared-markdown';
import { ComponentType, FC, PropsWithChildren, createContext } from 'react';

type LinkWrapper = FC<PropsWithChildren<{ trackingId: string; href: string }>>;
type LinkPreviewer = ComponentType<PropsWithChildren<{}>>;
type LinkRewriter = (href: string) => string;

interface MarkdownContext {
  LinkComponent: LinkWrapper | null;
  LinkPreviewer: LinkPreviewer | null;
  linkRewriter: LinkRewriter;
  extraComponents: Array<MarkdownComponent>;
  extraNodes: Array<MarkdownNode>;
}

const MarkdownContextInstance = createContext<MarkdownContext>({
  LinkComponent: null,
  LinkPreviewer: null,
  linkRewriter: (href: string) => href,
  extraComponents: [],
  extraNodes: [],
});

const MarkdownContextProvider = MarkdownContextInstance.Provider;

export {
  MarkdownContextProvider,
  type MarkdownContext,
  MarkdownContextInstance,
};
