import { baseQueryFactory } from '@squareup/dex-data-shared-base-api';
import { getNextPublicBaseURL } from '@squareup/dex-utils-environment';

const developerDataApiBaseQuery = baseQueryFactory({
  baseUrl: getNextPublicBaseURL(),
});

const devsContentApiBaseQuery = baseQueryFactory({
  baseUrl: getNextPublicBaseURL(),
});

export { developerDataApiBaseQuery, devsContentApiBaseQuery };
