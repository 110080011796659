import { StoreStateNode } from '@squareup/dex-types-data-state';
import {
  Decision,
  FeatureDetection,
} from '@squareup/dex-types-shared-feature-detection';
import { publishSystemError } from '@squareup/dex-utils-application-behavior-events';
import { publishActionableError } from '@squareup/dex-utils-error';
import { partitionMatched } from '@squareup/dex-utils-shared-array';

// TODO: Set up typing for features here. Need to figure out how to get proper types without looking at the producers.
abstract class FeatureProducer<TFlags extends object> {
  protected state: StoreStateNode<{ data: TFlags }> = {
    isLoading: true,
  };

  getState(): StoreStateNode<{ data: TFlags }> {
    return this.state;
  }

  abstract initialize(): Promise<FeatureProducer<TFlags>>;
}

function createFailedDecision(featureKey: string, message: string): Decision {
  return {
    enabled: false,
    featureKey,
    reasons: [message],
  };
}

/**
 * This client manages producers of feature and the life times thereof.
 * These producers will then return decisions which are then surfaced out of the client.
 */
class FeatureDetectionClient implements FeatureDetection {
  private producers: FeatureProducer<object>[] = [];
  private isClientReady = false;
  private features: object = {};
  private onUpdateCallbacks: (() => void)[] = [];

  public addProducer(producer: FeatureProducer<object>) {
    this.producers.push(producer);
  }

  /**
   * Initializes the client and all feature producers it holds.
   */
  public async initialize() {
    // Hydrate with any synchronous producer state up front.
    this.mergeAllProducerData();

    const promises: Promise<FeatureProducer<object>>[] = this.producers.map(
      (producer) => producer.initialize()
    );

    const { rejectedResults, fulfilledWithError, fulfilledWithoutError } =
      await this.partitionSettledResults(promises);

    // Publish out any errors
    this.publishRejections(rejectedResults);
    this.publishResultsWithError(fulfilledWithError);

    this.mergeResultsSignalUpdate(fulfilledWithoutError);
  }

  public decide(featureKey: string): Decision {
    return {
      enabled: this.resolveEnabled(this.features, featureKey),
      featureKey,
      reasons: [],
    };
  }

  public isReady(): boolean {
    return this.isClientReady;
  }

  public onUpdate(callback: () => void): () => void {
    this.onUpdateCallbacks.push(callback);
    return () => {
      this.onUpdateCallbacks = this.onUpdateCallbacks.filter(
        (c) => c !== callback
      );
    };
  }

  /**
   * Takes all results then merges that data into one object.
   * Signals an update to all listeners.
   */
  private mergeResultsSignalUpdate(
    results: PromiseFulfilledResult<FeatureProducer<object>>[]
  ) {
    // Take all data produced and merge it all together.
    this.mergeAllResultStateData(results);

    this.signalUpdate();
  }

  /**
   * Takes all results extracts the data fields,
   * then merges that data into one object.
   */
  private mergeAllResultStateData(
    results: PromiseFulfilledResult<FeatureProducer<object>>[]
  ) {
    // For now we arent smart about any kind of merge algo as we dont anticipate many producers + flags.
    // So just remove what we had and recalc.
    this.features = {};
    results.forEach((result) => {
      // Will work on typings for now just cast to object
      this.features = {
        ...this.features,
        ...result.value.getState().data,
      };
    });
  }

  /**
   * Merges all producer state into features
   */
  private mergeAllProducerData() {
    // So just remove what we had and recalc.
    this.features = {};

    this.producers.forEach((producer) => {
      // Will work on typings for now just cast to object
      this.features = {
        ...this.features,
        ...producer.getState().data,
      };
    });
  }

  /**
   * Settles all promises given.
   * Once settles returns 3 sets.
   * rejectedResults - A set of results from promises that rejected and could not complete.
   * statesWithError - A set of results that fulfilled with states that returned errors.
   * statesWithData -A set of results that fulfilled with no error.
   */
  private async partitionSettledResults(
    promises: Promise<FeatureProducer<object>>[]
  ) {
    // For now we will optimistically move forward with some producers fullfilled and some rejected, we may move to Promise.all
    const settled = await Promise.allSettled(promises);

    // Patition by promises that fulfulled and rejected
    const [rejectedResults, fulfilledResults] = partitionMatched<
      PromiseSettledResult<FeatureProducer<object>>,
      PromiseRejectedResult,
      PromiseFulfilledResult<FeatureProducer<object>>
    >(settled, (result) => result.status === 'rejected');

    // Partition by states with errors and without
    const [fulfilledWithError, fulfilledWithoutError] = partitionMatched<
      PromiseFulfilledResult<FeatureProducer<object>>,
      PromiseFulfilledResult<FeatureProducer<object>>,
      PromiseFulfilledResult<FeatureProducer<object>>
    >(fulfilledResults, (result) => Boolean(result.value.getState().error));

    return { rejectedResults, fulfilledWithError, fulfilledWithoutError };
  }

  /**
   * Takes a features objects and a key and attempts to resolve the value
   * on the object for that key to a boolean. If the value is a 'true'
   * string or true boolean true is returned, false otherwise.
   * For now its expected producers will provide a state that is key/value.
   * We can make the change later to support more.
   */
  private resolveEnabled(features: object, key: string): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value = (features as any)[key];

    if (
      (typeof value === 'string' && value === 'true') ||
      (typeof value === 'boolean' && value === true)
    ) {
      return true;
    }

    // Any other type should be disabled.
    return false;
  }

  private signalUpdate() {
    this.isClientReady = true;
    this.onUpdateCallbacks.forEach((c) => c());
  }

  /**
   * Publishes an init error with the count and what happened as the error.
   */
  private publishFailedInitialization(
    errorCount: number,
    whatHappened: string
  ) {
    // TODO: It would be nice to know what producers failed. We should have a human readable key to use here once we have many.
    const error = new Error(
      `Initialization of ${errorCount} feature producers ${whatHappened}`
    );
    publishSystemError({
      message:
        'Loading default states for all features produced by these producers',
      error,
    });
  }

  /**
   * Publishes all promise rejections given.
   */
  private publishRejections(rejections: PromiseRejectedResult[]) {
    const count = rejections.length;

    if (count > 0) {
      this.publishFailedInitialization(count, 'failed to execute.');

      rejections.forEach((rejection) => {
        publishSystemError({
          message: rejection.reason,
        });
      });
    }
  }

  /**
   * Publishes all state error transions given.
   */
  private publishResultsWithError(
    results: PromiseFulfilledResult<FeatureProducer<object>>[]
  ) {
    const count = results.length;

    if (count > 0) {
      this.publishFailedInitialization(count, 'returned error states.');

      results.forEach((result) => {
        publishActionableError(result.value.getState().error);
      });
    }
  }
}

export { FeatureDetectionClient, createFailedDecision, FeatureProducer };

export {
  type Decision,
  type FeatureDetection,
} from '@squareup/dex-types-shared-feature-detection';
