type BaseQueryArgs = {
  additionalHeaders?: HeadersInit;
  body?: BodyInit;
  method?: string;
  path?: string;
  useCache?: boolean;
  baseUrlOverride?: string | undefined;
  // The app name from registry, to be used to make s2s calls
  appName?: string | undefined;
  // Friendly name for the API call when viewed in telemetry
  metricsHandlerName?: string | undefined;
};

/**
 * Merges the current server quest args with the extra args given.
 */
function mergeQueryArgs<TArgs extends BaseQueryArgs>(
  queryArgs: TArgs | undefined,
  extraQueryArgs: TArgs | undefined
) {
  return {
    ...queryArgs,
    ...extraQueryArgs,
    additionalHeaders: {
      ...queryArgs?.additionalHeaders,
      ...extraQueryArgs?.additionalHeaders,
    },
  };
}

export { mergeQueryArgs, type BaseQueryArgs };
