import { WithNullableProperties } from '@squareup/dex-types-shared-utils';
import {
  useParams as useNextParams,
  usePathname as useNavPathname,
  useSearchParams as useNextSearchParams,
} from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';

const ROUTER_NOT_READY = 'router-not-ready';

type Params = WithNullableProperties<ReturnType<typeof useNextParams>>;

/**
 * NextJs 13 typings for useParams are busted as they claim any key you lookup has a value.
 * Its <string, string> when it should be <string, string | undefined>.
 */
const useParams = (): Params => {
  let params = useNextParams();
  const search = useNextSearchParams();

  // This api will also return null if used in /pages. So fallback to the search api in that case.
  if (!params) {
    params = Object.fromEntries<string>(search || new URLSearchParams());
  }

  return params;
};

/**
 * Returns the values after '#' parsed.
 * As it stands there is not api for this that works in /pages and /app.
 * Once we migrate this may go away.
 */
const useHash = () => {
  const [parsedHash, setParsedHash] = useState(new URLSearchParams());

  useEffect(() => {
    const hash = window.location.hash.split('#')[1];
    setParsedHash(new URLSearchParams(hash));
  }, []);

  return parsedHash;
};

/**
 * When the router is not ready in /pages directory isReady:false will be returned.
 * Next returns null from its path name hook in this case.
 */
const usePathname = ():
  | { pathName: string; isReady: true }
  | { pathName: null; isReady: false } => {
  const pathName = useNavPathname();

  if (pathName) {
    return {
      pathName,
      isReady: true,
    };
  } else {
    return {
      pathName: null,
      isReady: false,
    };
  }
};

const useNavigationEvent = () => {
  const pathname = usePathname(); // Get current route
  const savedPathNameRef = useRef(pathname);
  const callbackRef = useRef<undefined | (() => void)>(undefined);

  useEffect(() => {
    // If path changes let the on path name change callback know
    if (savedPathNameRef.current !== pathname) {
      callbackRef.current && callbackRef.current();
      savedPathNameRef.current = pathname;
    }
    return () => {
      callbackRef.current = undefined;
    };
  }, [pathname]);

  return (onPathnameChange: () => void) => {
    callbackRef.current = onPathnameChange;
  };
};

const useSearchParamsObject = () => {
  const params = useNextSearchParams();
  return Object.fromEntries<string | undefined>(
    params || new URLSearchParams()
  );
};

const usePathWithoutHash = () => {
  const { asPath } = useRouter();
  const currentPathWithoutHash = useRef<string>('');

  // const { asPath } useRouter is not stable
  // Anytime you click a TOC/FTOC item, it re-renders because
  // next.js' context changes when the hash changes.
  // To avoid, we'll memoize the path without the hash, and
  // only rerender if it changes
  return useMemo(() => {
    const path = asPath.split('?')?.[0]?.split('#', 1)?.[0] || '';
    if (currentPathWithoutHash.current !== path) {
      currentPathWithoutHash.current = path;
    }

    return currentPathWithoutHash.current;
  }, [asPath]);
};

export {
  useParams,
  useHash,
  usePathname,
  ROUTER_NOT_READY,
  useNavigationEvent,
  useSearchParamsObject,
  usePathWithoutHash,
  type Params,
};
export { useRouter, useSearchParams } from 'next/navigation';
