import {
  isPublicContext,
  isServerContext,
} from '@squareup/dex-utils-environment';

// DataDog defines environments as prod, pre-prod, staging, etc.
const dataDogEnvironmentMap = new Map([
  ['production', 'prod'],
  ['staging', 'staging'],
]);

interface DatadogLogsOptions {
  clientToken: string;
  service: string;
  env: string;
  version: string;
}

function importDatdogLogs() {
  return import('@datadog/browser-logs');
}

type DatadogLogs = Awaited<ReturnType<typeof importDatdogLogs>>['datadogLogs'];

let logsInstance: DatadogLogs;
async function initializeDatadogLogs(
  options: DatadogLogsOptions
): Promise<DatadogLogs | null> {
  if (!isValidConfig(options)) {
    return null;
  }

  if (logsInstance) {
    return logsInstance;
  }

  const { datadogLogs } = await importDatdogLogs();
  datadogLogs.init({
    clientToken: options.clientToken,
    site: 'datadoghq.com',
    service: options.service,
    env: options.env,
    version: options.version,
    // Starting by sampling at 50%
    sessionSampleRate: 50,
  });

  // eslint-disable-next-line require-atomic-updates
  logsInstance = datadogLogs;
  return datadogLogs;
}

function isValidConfig(options: DatadogLogsOptions) {
  return (
    !isServerContext() &&
    isPublicContext() &&
    dataDogEnvironmentMap.has(options.env) &&
    options.clientToken
  );
}

export { initializeDatadogLogs };
