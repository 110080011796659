import { publishSystemError } from '@squareup/dex-utils-application-behavior-events';

const MAX_LOAD_RETRY_COUNT = 2;

/**
 * Responsible for dynamically loading a jsavascript resource.
 * Addes a script tag to head.
 * Once created the consumer can add custom attrs as needed via onCreated.
 * Does a few rudimentary retries on failure.
 */
class ScriptLoader {
  private loadRetryCount = 0;
  private script: HTMLScriptElement | undefined;

  /**
   * Returns true if a load attempt has never happened.
   */
  public get canLoad(): boolean {
    return !this.script;
  }

  /**
   * Loads script into the head.
   * @param win - The window to load the script into.
   * @param onCreated - A callback function handed the script element.
   * This allows for setting custom attributes to the element such as src.
   *
   * Will retry a few times on load failure.
   * @returns - Resolves when the script is loaded. Rejects once retries are exhausted.
   */
  public load(
    win: Window,
    onCreated?: (script: HTMLScriptElement) => void
  ): Promise<void> {
    if (this.script) {
      const error = new Error(
        `Already tried loading the script: ${this.script?.src}`
      );
      publishSystemError({
        message: 'Error loading script in script loader',
        error,
      });
      return Promise.reject(error);
    }

    return new Promise((resolve, reject) => {
      this.script = win.document.createElement('script');

      if (onCreated) {
        try {
          onCreated(this.script);
        } catch (error) {
          publishSystemError({
            message: 'Error loading script in script loader',
            error,
          });
          reject(error);
        }
      }

      this.script.setAttribute('type', 'text/javascript');
      this.script.setAttribute('charset', 'utf8');

      this.script.onload = () => {
        resolve();
      };

      this.script.onerror = (
        _event: Event | string,
        _source: string | undefined,
        _lineno: number | undefined,
        _colno: number | undefined,
        error: Error | undefined
      ) => {
        // Just try a few times then give up
        if (this.loadRetryCount === MAX_LOAD_RETRY_COUNT) {
          const err = new Error(
            `Maxed out ${this.loadRetryCount} retries with error:${error?.message} attemping to load script:${this.script?.src}`
          );
          publishSystemError({
            message: 'Error loading script in script loader',
            error: err,
          });
          reject(err);
        } else {
          this.loadRetryCount++;
          this.removeScript(win.document);
          this.load(win, onCreated).then(resolve).catch(reject);
        }
      };

      // If not prepended, it gets somehow removed by ember-cli-head
      win.document.head.prepend(this.script);
    });
  }

  private removeScript(windowDocument: Document) {
    // If our script already exists remove it before loading once more
    this.script && windowDocument.head.removeChild(this.script);

    this.script = undefined;
  }
}

export { ScriptLoader };
