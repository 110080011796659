import {
  BaseQueryError,
  QueryReturnValue,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  EndpointBuilder,
  type DefinitionType,
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { HTTP_METHOD } from '@squareup/dex-types-shared-http';
import { isServerContext } from '@squareup/dex-utils-environment';
import { FetchCacheManager } from '@squareup/dex-utils-fetch-cache-manager';
import { BaseQueryArgs } from '@squareup/dex-utils-shared-base-api';

import { getCsrfFromCookie } from './get-csrf-from-cookie';

const RESPONSE_CACHE_VERSION = 'v1';
let cacheManager: FetchCacheManager | undefined;

type BaseQueryConfig = {
  baseUrl: string;
};

type FetchErrorDetails = {
  error: string;
  originalStatus: number;
  status: string;
  type: string | undefined;
  message: string | undefined;
  field: string | undefined;
  detail: string | undefined;
  data: string | undefined;
};

type FetchError = {
  error: FetchErrorDetails;
};

const getFetchCacheManager = () => {
  if (!cacheManager) {
    cacheManager = new FetchCacheManager(RESPONSE_CACHE_VERSION);
  }

  return cacheManager;
};

const clearFetchCache = () => {
  cacheManager = undefined;
};

const baseQueryFactory = ({ baseUrl }: BaseQueryConfig) => {
  // eslint-disable-next-line complexity
  return async <TResult>({
    additionalHeaders = {},
    body,
    method = HTTP_METHOD.GET,
    path,
    useCache = false,
    baseUrlOverride,
    metricsHandlerName,
    appName,
  }: BaseQueryArgs) => {
    const url = new URL(`${baseUrlOverride || baseUrl}${path}`).toString();

    let cachedResponse;
    let etag;
    if (useCache) {
      cachedResponse = await getFetchCacheManager().findResponse(url);
      etag = cachedResponse?.headers.get('ETag');
    }

    // TODO: Implement a better mechanism for CSRF once auth is in place
    const isCsrfMethod =
      method !== HTTP_METHOD.GET && !url.includes('explorer-gateway');
    const csrf = isCsrfMethod ? getCsrfFromCookie() : null;

    const headers: HeadersInit = {
      Accept: 'application/json',
      ...(etag ? { 'If-None-Match': etag } : null),
      ...(csrf ? { 'X-CSRF-TOKEN': csrf } : null),
      ...additionalHeaders,
    };

    const metricsClient = globalThis.metricsClient;
    const s2sConnector = globalThis.s2sConnector;

    const fetchApi =
      isServerContext() && appName && s2sConnector
        ? (url: string, options: RequestInit) =>
            s2sConnector.fetch(
              url,
              appName,
              options
            ) as unknown as Promise<Response>
        : fetch;

    let fetchResponse: Response | undefined;
    const fetchStartTime = Date.now();
    try {
      fetchResponse = await fetchApi(url, {
        credentials: 'include',
        headers,
        method,
        ...(body ? { body } : null),
      });
    } catch (error) {
      // Logging a -1 in case of errors, so we can quantify
      if (metricsClient) {
        metricsClient.emitHttpStatusMetric(url, method, -1, metricsHandlerName);
      }

      return {
        error: {
          error: `Fetch unable to be made. - '${
            (error as Error).message
          }'. Request url: ${url} ${
            etag ? `headers: 'If-None-Match': ${etag}` : ''
          }. Full error - ${JSON.stringify(error)}`,
          status: 'FETCH_ERROR',
        },
      };
    }

    if (metricsClient) {
      metricsClient.emitHttpStatusMetric(
        url,
        method,
        fetchResponse.status,
        metricsHandlerName
      );
      metricsClient.emitHttpLatencyMetric(
        url,
        method,
        Date.now() - fetchStartTime,
        metricsHandlerName
      );
    }

    const responseDetails = {
      response: fetchResponse,
      isCache: false,
    };

    if (fetchResponse.status === 204) {
      // Request was successful, but returned no data (such as a deletion)
      // There is nothing to parse and nothing to cache, so bail

      return {
        data: {} as NonNullable<TResult>,
        meta: { headers: fetchResponse.headers },
      };
    }

    if (fetchResponse.status === 304 && cachedResponse && etag) {
      responseDetails.response = cachedResponse;
      responseDetails.isCache = true;
    } else if (!fetchResponse.ok) {
      const errorObj = {
        error: {
          error: `Fetch returned an unsuccessful response status. ${fetchResponse.statusText} ${fetchResponse.status}`,
          originalStatus: fetchResponse.status,
          status: 'CUSTOM_ERROR',
          type: undefined,
          data: undefined,
          /**
           * Some portal endpoints use a detail, message and field, attribute on the error response payload
           */
          detail: undefined,
          message: undefined,
          field: undefined,
        },
      };

      // check if there is detail or type on the error response
      try {
        const json = await fetchResponse.json();
        errorObj.error.type =
          json.errors && json.errors.length > 0 ? json.errors[0].type : '';
        errorObj.error.detail =
          json.errors && json.errors.length > 0 ? json.errors[0].detail : '';
        errorObj.error.message =
          json.errors && json.errors.length > 0 ? json.errors[0].message : '';
        errorObj.error.field =
          json.errors && json.errors.length > 0 ? json.errors[0].field : '';
      } catch {
        // no op
      }
      return errorObj;
    } else if (useCache) {
      // Store the response for future calls
      await getFetchCacheManager().storeResponse(url, fetchResponse);
    }

    try {
      const json = (await responseDetails.response.json()) as TResult;
      if (!json) {
        // An invalid cache object should be impossible. But
        // this is a safe way to reset your cache
        if (responseDetails.isCache) {
          await getFetchCacheManager().deleteCacheEntry(url);
        }

        return {
          error: {
            data: `Request url: ${url} - has cache: ${responseDetails.isCache}`,
            error: 'Fetch returned no JSON. Unable to parse.',
            originalStatus: responseDetails.response.status,
            status: 'PARSING_ERROR',
          },
        };
      }

      return {
        data: json,
        meta: { headers: responseDetails.response.headers },
      };
    } catch (error) {
      // An invalid cache object should be impossible. But
      // this is a safe way to reset your cache
      if (responseDetails.isCache) {
        await getFetchCacheManager().deleteCacheEntry(url);
      }

      return {
        error: {
          data: `Request url: ${url} - has cache: ${responseDetails.isCache}`,
          error: `Fetch returned invalid JSON. Unable to parse. ${
            (error as Error).message
          }`,
          originalStatus: responseDetails.response.status,
          status: 'PARSING_ERROR',
        },
      };
    }
  };
};

type BaseQuery = ReturnType<typeof baseQueryFactory>;
interface BaseQueryMetaResult {
  headers: Headers;
}

type BaseEndpointBuilder<TReducerPath extends string> = EndpointBuilder<
  <TResult>({
    additionalHeaders,
    body,
    method,
    path,
  }: BaseQueryArgs) => Promise<
    QueryReturnValue<TResult, BaseQueryError<BaseQuery>, BaseQueryMetaResult>
  >,
  string,
  TReducerPath
>;

const endpointBuilder = {
  query: <TOptions extends object>(options: TOptions) => ({
    ...options,
    type: 'query' as DefinitionType.query,
  }),
  mutation: <TOptions extends object>(options: TOptions) => ({
    ...options,
    type: 'mutation' as DefinitionType.mutation,
  }),
};

export {
  type BaseQuery,
  type BaseQueryMetaResult,
  type BaseEndpointBuilder,
  type FetchErrorDetails,
  type FetchError,
  baseQueryFactory,
  endpointBuilder,
  clearFetchCache,
};
