import { Grid } from '@squareup/dex-ui-shared-grid';
import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';

import styles from './content-layout-grid.module.css';

export interface ContentLayoutProps {
  children: ReactNode;
  singleColumn?: boolean;
}

const ContentLayoutGrid: FC<ContentLayoutProps> = ({
  children,
  singleColumn,
}) => {
  const render = () => {
    return <>{children}</>;
  };

  return (
    <Grid
      className={clsx(singleColumn && styles['single-column'])}
      variant={singleColumn ? 'content-single' : 'content'}
      render={render}
    />
  );
};

export { ContentLayoutGrid };
