import { SQFlagsFeatureProducer } from '@squareup/dex-data-shared-sq-flags-producer';
import { StoreStateNode } from '@squareup/dex-types-data-state';
import {
  HybridAppInitProps,
  ServerAppInitResults,
} from '@squareup/dex-types-shared-app';
import { Flags } from '@squareup/dex-types-shared-developer-api';
import {
  UserEvent,
  TaskEvent,
  initTaskEvent,
} from '@squareup/dex-utils-application-behavior-events';
import {
  getEnvironmentName,
  isProductionEnvironment,
  isServerContext,
} from '@squareup/dex-utils-environment';
import {
  publishActionableError,
  unhandledToInternalServerError,
} from '@squareup/dex-utils-error';
import { QSPFlagsFeatureProducer } from '@squareup/dex-utils-shared-qsp-flags-producer';
import { initializeDatadogRUM } from '@squareup/dex-utils-shared-real-user-monitoring';

let previousRequestId: string | undefined;
let initResults: Promise<StoreStateNode<void>> | undefined;

const navigationAction: UserEvent = {
  action: 'navigate',
  onType: 'document',
  onIdentifier: 'dex-tech-ref',
  context: 'global',
};

const taskEvent: TaskEvent = {
  context: 'application',
  action: 'task',
  onType: 'util',
  onIdentifier: 'hybrid-app-init',
  status: 'start',
};

/**
 * A init function that should only be called one time in the lifetime of a js context.
 * This will have side effects standing up all infra.
 */
async function initialize(
  initProps: HybridAppInitProps,
  serverData: ServerAppInitResults<never, Flags>['data']
) {
  const results: StoreStateNode<void> = { isLoading: false, error: undefined };
  const { searchParams, featureDetection } = initProps;
  const { end } = initTaskEvent(taskEvent);

  try {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initializeDatadogRUM({
      applicationId: process.env.NEXT_PUBLIC_RUM_APPLICATION_ID || '',
      clientToken: process.env.NEXT_PUBLIC_RUM_CLIENT_TOKEN || '',
      env: getEnvironmentName(),
      version: process.env.NEXT_PUBLIC_APP_BUILD_ID || '',
      service: 'dex-tech-fe',
    });

    // Init feature detection with any server data passed
    const sqProducer = new SQFlagsFeatureProducer(serverData?.getFlags);
    featureDetection.addProducer(sqProducer);

    // Do not allow qsp overides in production.
    if (!isProductionEnvironment() && searchParams['features']) {
      const qspProducer = new QSPFlagsFeatureProducer({ searchParams });
      featureDetection.addProducer(qspProducer);
    }

    // NOTE that any async activity should be here and returned on a promise all.
    // Allowing all synch init to happen above.
    await featureDetection.initialize();
  } catch (error) {
    results.error = unhandledToInternalServerError(
      error,
      'initializing application'
    );
    publishActionableError(results.error);
  }

  end();

  return results;
}

/**
 * Application initialization state should only be shared within one page request.
 * - Server: Any new page request
 * - Browser: Intial page request
 */
function clearStatePerJsContext(requestId: string | undefined) {
  if (
    !previousRequestId ||
    (previousRequestId !== requestId && isServerContext())
  ) {
    previousRequestId = requestId;
    initResults = undefined;
  }
}

/**
 * Does all initialization which is needed for the hybrid application.
 * Initialization refers to state fetch/hydration of configured dependancies.
 * Hybrid app refers to the application logic running on both the server and browser.
 *
 * NOTE: All module level state created here persists for the lifetime of the page.
 * - Server: Any new page request
 * - Browser: Intial page request
 */
function initializeApp(
  initProps: HybridAppInitProps,
  serverData: ServerAppInitResults<never, Flags>['data']
): Promise<StoreStateNode<void>> {
  clearStatePerJsContext(initProps.requestId);

  if (!initResults) {
    // eslint-disable-next-line require-atomic-updates
    initResults = initialize(initProps, serverData);
  }

  return initResults;
}

export { initializeApp, navigationAction, clearStatePerJsContext };
