import { FeatureDetection } from '@squareup/dex-utils-shared-feature-detection';
import { createContext } from 'react';

interface FeatureDetectionContext {
  client: FeatureDetection | null;
}

const FeatureDetectionContextInstance = createContext<FeatureDetectionContext>({
  client: null,
});

const FeatureDetectionContextProvider =
  FeatureDetectionContextInstance.Provider;

export {
  FeatureDetectionContextProvider,
  type FeatureDetectionContext,
  FeatureDetectionContextInstance,
};
