import { Graph } from '@square/ignition';
import { SpecVersion, ReferenceSpecification } from '@squareup/dex-types-oas';
import { publishSystemError } from '@squareup/dex-utils-application-behavior-events';

// Graphs are kept at module level for now will prob move this to a class instance.
const graphVersionMap: Map<SpecVersion, Graph> = new Map();

const findGraph = (version?: SpecVersion | undefined): Graph | undefined => {
  if (version && graphVersionMap.has(version)) {
    return graphVersionMap.get(version);
  }

  return undefined;
};

/**
 * Gets the open api graph given a specification.
 * If there is an issue parsing the spec undefined is returned.
 */
const parseGraph = (
  jsonParser: (content: string) => Graph,
  spec: ReferenceSpecification
): Graph | undefined => {
  const content = spec.content.content;
  // For the unpublished spec, we are using document id as the key
  const key =
    'version' in spec.content ? spec.content.version : spec.content.document_id;

  // Only parse if we have not already parsed this graph
  if (!findGraph(key)) {
    try {
      const graph = jsonParser(content) as Graph;
      graphVersionMap.set(key, graph);
    } catch (error) {
      publishSystemError({
        message: 'Error parsing graph content from JSON',
        error,
      });
      return undefined;
    }
  }
  return graphVersionMap.get(key);
};

/**
 * When called will load up the fraph parser.
 */
const loadGraphParser = async (): Promise<{
  parse: (spec: ReferenceSpecification) => Graph | undefined;
}> => {
  // Kick off a request to fetch the ignition parser.
  const { Graph } = await import('@square/ignition');
  return {
    parse: (spec: ReferenceSpecification) => parseGraph(Graph.fromJSON, spec),
  };
};

export { loadGraphParser, findGraph };
