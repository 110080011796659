import { convertSparkupEntityToUrl } from './entity-url-transform';

/**
 * Matches the following string types:
 * entityType:entityToken-additionalEntityToken#entityHash
 *
 * The 3 groups are entityType, tokenList, and hash.
 */
const ENTITY_URL_MATCHER =
  /^(?<entityType>[\w-]+):(?<tokenList>[\w.-]+)(?<hash>#.+)?/;

interface EntityUrlMatchGroup {
  entityType?: string;
  tokenList?: string;
  hash?: string;
}

function entityUrlTransformer(href: string): string {
  const hrefMatch = ENTITY_URL_MATCHER.exec(href);
  if (!hrefMatch) {
    return href;
  }

  const group = hrefMatch.groups as EntityUrlMatchGroup;

  return (
    convertSparkupEntityToUrl(
      '/reference/square',
      group.entityType || '',
      group.tokenList?.split('-') || [],
      group.hash
    ) || href
  );
}

export { entityUrlTransformer };
