import { InteractiveEvent } from '@squareup/dex-types-shared-ui';
import {
  publishUserAction,
  UserActionTypes,
  interactableNames,
  UserInteractableTypes,
} from '@squareup/dex-utils-application-behavior-events';
import { tryParseJsonString } from '@squareup/dex-utils-text';
import React, { ReactNode } from 'react';

const DEFAULT_CONTEXT = 'global';
const trackedElementSelector = interactableNames.join(',');

/**
 * When added to the root of the React tree will capture all user interactions
 * with interactable components and publish thier meta data to the application
 * behavior events for all registered consumers of User interactions.
 *
 * For any given interaction if there is no surrounding TrackedBox container the
 * interaction will be assumed to be at a global scope.
 */
const UserInteractionPublisher = ({
  children,
}: {
  children?: ReactNode | undefined;
}) => {
  const logInteraction = (e: InteractiveEvent) => {
    let target: HTMLElement | null = e.target;

    if (!target) {
      return;
    }

    let tagName = target.tagName.toLowerCase();

    // If the target is not labeled interactable we must derrive the interactive item.
    // We are using event delegation here on the root. Thus we need to grab the closest parent or self that is
    // actionable and assume thats the element the user is interacting with.
    if (!target.dataset.interactable) {
      target = target.closest(trackedElementSelector);
      if (!target) {
        return;
      }

      tagName = target.tagName.toLowerCase();

      // Filter out all interactions on element types we do not care about.
      if (!interactableNames.includes(tagName as UserInteractableTypes)) {
        return;
      }
    }

    // NOTE: Interactable escape hatch allows consumers to provide an element of choice so cast
    const onType = tagName.replace('market-', '') as UserInteractableTypes;
    const action = e.type as UserActionTypes;
    let context = DEFAULT_CONTEXT;

    // If the action occured within user interaction area(s) grab the tracking id(s) and chain them together.
    if (
      e.extraInteractionData?.trackingIds &&
      e.extraInteractionData.trackingIds.length > 0
    ) {
      context = e.extraInteractionData.trackingIds?.join('_');
    }

    const onIdentifier =
      target.dataset.trackingId?.toLowerCase() ||
      'BUG: ID not supplied by application code';
    let extra = target.dataset.trackingExtra;

    // Automatically log the href if it's an anchor tag when clicked
    const hrefTags = ['A', 'MARKET-LINK', 'MARKET-BUTTON'];
    if (hrefTags.includes(target.tagName)) {
      const href = target.getAttribute('href');
      if (href) {
        const extraJson = extra
          ? tryParseJsonString(extra) || { extraValue: extra }
          : {};
        extra = JSON.stringify({ href, ...extraJson });
      }
    }

    publishUserAction({
      action,
      onType,
      onIdentifier,
      context,
      extra,
    });
  };

  /* eslint-disable custom-rules/prefer-dex-ui-to-native */
  return (
    <div
      id="user-interaction-publisher"
      onChange={logInteraction}
      onClick={logInteraction}
    >
      {children}
    </div>
  );
};

export { UserInteractionPublisher, DEFAULT_CONTEXT as DEFAULT_CATEGORY };
