import {
  getEnvironmentName,
  isServerContext,
} from '@squareup/dex-utils-environment';
// import React from 'react';

if (getEnvironmentName() === 'development' && !isServerContext()) {
  /**
   * For some odd reason the required package was making it way into the _app bundle for prod.
   * If you use this be sure to not checkin
   *
   * To use why did you render.
   *
   * Add MyComponent.whyDidYouRender = true;
   * https://github.com/vercel/next.js/blob/canary/examples/with-why-did-you-render/next.config.js
   */
  // const whyDidYouRender = require('@welldone-software/why-did-you-render');
  // whyDidYouRender(React, {
  //   trackAllPureComponents: true,
  // });
}
