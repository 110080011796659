import { AsProp, NullableClassName } from '@squareup/dex-types-shared-ui';
import clsx from 'clsx';
import React, { FunctionComponent, ReactNode } from 'react';

import { Box, BoxProps } from '../Box';

import styles from './heading.module.css';

type ColorVariants = {
  colorVariant?:
    | '10'
    | '20'
    | '30'
    | 'inverse'
    | 'black'
    | 'white'
    | 'currentColor';
};

type HeadingProps = NullableClassName &
  AsProp &
  BoxProps & { children: ReactNode } & ColorVariants;
type HeadingVariants = { variant: '5' | '10' | '20' | '30' };
type DisplayVariants = { variant: '10' | '20' };

const Display: FunctionComponent<
  HeadingProps & DisplayVariants & { defaultTag: string }
> = (props) => {
  const {
    children,
    className,
    as,
    variant,
    defaultTag,
    colorVariant,
    ...rest
  } = props;
  return (
    <Box
      {...rest}
      as={as || defaultTag}
      className={clsx(
        styles['base'],
        styles[`display-${variant}`],
        colorVariant && styles[`color-${colorVariant}` as keyof typeof styles],
        className
      )}
    >
      {children}
    </Box>
  );
};

const Heading: FunctionComponent<
  HeadingProps & HeadingVariants & { defaultTag: string }
> = (props) => {
  const {
    children,
    className,
    as,
    variant,
    defaultTag,
    colorVariant,
    ...rest
  } = props;
  return (
    <Box
      {...rest}
      as={as || defaultTag}
      className={clsx(
        styles['base'],
        styles[`heading-${variant}`],
        colorVariant && styles[`color-${colorVariant}` as keyof typeof styles],
        className
      )}
    >
      {children}
    </Box>
  );
};

const Display20: FunctionComponent<HeadingProps> = (props) => {
  return <Display defaultTag="h1" variant="20" {...props} />;
};

const Display10: FunctionComponent<HeadingProps> = (props) => {
  return <Display defaultTag="h1" variant="10" {...props} />;
};

const Heading30: FunctionComponent<HeadingProps> = (props) => {
  return <Heading defaultTag="h2" variant="30" {...props} />;
};

const Heading20: FunctionComponent<HeadingProps> = (props) => {
  return <Heading defaultTag="h3" variant="20" {...props} />;
};

const Heading10: FunctionComponent<HeadingProps> = (props) => {
  return <Heading defaultTag="h4" variant="10" {...props} />;
};

const Heading5: FunctionComponent<HeadingProps> = (props) => {
  return <Heading defaultTag="h5" variant="5" {...props} />;
};

export { Display20, Display10, Heading30, Heading20, Heading10, Heading5 };
