import { getDeveloperApi } from '@squareup/dex-data-shared-developer-api';
import { LinkPreviewPopoverLayout, Popover } from '@squareup/dex-ui';
import React, { FC, PropsWithChildren, useCallback, useRef } from 'react';

interface LinkPreviewerProps {}

const POPOVER_DELAY_MS = 250;

const LinkPreviewer: FC<PropsWithChildren<LinkPreviewerProps>> = ({
  children,
}) => {
  const popoverRef = useRef<globalThis.HTMLMarketDropdownElement>(null);

  // We're gonna keep track of whether we tried to open the popover. The reason for this is
  // so that we can show the popover once the data is finished fetching, and they're still hovering
  const isPopoverOpen = useRef(false);

  const [trigger, result] = getDeveloperApi().useLazyGetSearchRecordQuery();

  // We lazy load the popover data, but we also want a min 250ms delay before showing the content
  // even if cached. Therefore, we'll do the following:
  // 1. If we have the data, wait 250ms and then show the content
  // 2. Make the API call if we don't have data. We start the timer already, and if the
  // api call resolves before 250ms, we wait for the remainder.
  const onPopoverShouldOpen = useCallback(
    async (e: HTMLElement) => {
      isPopoverOpen.current = true;

      if (
        result.isLoading ||
        result.isError ||
        (result.data && !result.data.description)
      ) {
        return;
      }

      // If we already have the data, we're going to force a 150ms
      // delay
      if (result.data) {
        await new Promise((resolve) => setTimeout(resolve, POPOVER_DELAY_MS));
        // Only open it if the user is still hovering after the timeout
        if (isPopoverOpen.current) {
          popoverRef.current?.updateDropdownPosition();
          popoverRef.current?.openDropdown();
        }
        return;
      }

      // The href value on the actual anchor element will be
      // the absolute URL. So if the user passed `/data`, the
      // href on the anchor will look at the current domain, and
      // return https://developer.squareup.com/data for example
      const fullHref = (e as HTMLAnchorElement).href;
      if (!fullHref) {
        return;
      }

      const startTime = Date.now();
      const triggerResult = await trigger(
        { url: fullHref, filterText: true },
        true
      );

      const duration = Date.now() - startTime;
      if (duration < POPOVER_DELAY_MS) {
        await new Promise((resolve) =>
          setTimeout(resolve, POPOVER_DELAY_MS - duration)
        );
      }

      if (triggerResult.data?.description && isPopoverOpen.current) {
        popoverRef.current?.updateDropdownPosition();
        popoverRef.current?.openDropdown();
      }
    },
    [result.data, result.isError, result.isLoading, trigger]
  );

  const onPopoverShouldClose = useCallback(() => {
    isPopoverOpen.current = false;
    popoverRef.current?.closeDropdown();
  }, []);

  const previewContent = result?.data ? (
    <LinkPreviewPopoverLayout footer={result.data.display_category}>
      {result.data.description}
    </LinkPreviewPopoverLayout>
  ) : null;

  return (
    <Popover
      testId="link-preview-popover"
      disableInteractions={true}
      target={children}
      ref={popoverRef}
      onMouseEnter={onPopoverShouldOpen}
      onMouseLeave={onPopoverShouldClose}
      onFocus={onPopoverShouldOpen}
      onBlur={onPopoverShouldClose}
    >
      {previewContent}
    </Popover>
  );
};

export { LinkPreviewer };
