/**
 * Splits a camel case word into tokens, and returns a string
 * @param str The string to separate
 * @param separator The string to separate the breaks with
 * @returns A string where a separator is added between the lower and
 */
function splitCamelCase(str: string, separator = ' '): string {
  return str.replace(/([a-z])([A-Z])/g, `$1${separator}$2`);
}

/**
 * Splits a camel case word into tokens, and returns a string array
 * @param str The string to separate
 * @param separator The string to separate the breaks with
 * @returns A separater-delimitted array
 */
function splitCamelCaseIntoParts(str: string, separator = ' '): string[] {
  return splitCamelCase(str, separator).split(separator);
}

/**
 * Splits a slug into tokens, generally removing / and -
 * @param slug The slug to split
 * @param separator The separator between parts
 * @returns A single string split into parts by the separator
 */
function splitSlug(slug: string, separator = ' '): string {
  return splitSlugIntoParts(slug).join(separator);
}

/**
 * Splits a slug into tokens, generally removing / and -
 * @param slug The slug to split
 * @returns A list of strings without the - or /
 */
function splitSlugIntoParts(slug: string): string[] {
  return slug.split(/\/|-/).filter((word) => word);
}

/**
 * Splits a word by period
 * @param str The string to split
 * @returns The word split by period
 */
function splitPeriod(str: string, separator = ' '): string {
  return splitPeriodIntoParts(str).join(separator);
}

/**
 * Splits a word by period
 * @param str The string to split
 * @returns The word split by period
 */
function splitPeriodIntoParts(str: string): string[] {
  return str.split('.');
}

/**
 * Converts a capitalized word to a decapitalized one.
 * @param str The string to decapitalize
 * @returns A decapitalized word
 */
function decapitalize(str: string): string {
  if (!str[0]) {
    return '';
  }

  return str[0].toLowerCase() + str.slice(1);
}

/**
 * Converts a snake_case string to Title Case.
 * e.g. "foo_bar_baz" -> "Foo Bar Baz"
 * @param input The string to titleify
 * @returns string
 */
function titleifySnakeCaseString(input: string): string {
  if (!input) {
    return '';
  }
  let words = input.split('_');

  words = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return words.join(' ');
}

/**
 * Creates a human readable name from a string. Generally
 * these are related to APIs
 * @param name The name to make human readable
 * @param options Options for the name. Either sentence or title case. Defaults to sentence
 * @returns
 */
function humanizeName(
  name: string,
  options?: {
    style?: 'sentence' | 'title';
  }
): string {
  let words = splitCamelCaseIntoParts(name);

  if (!options?.style || options?.style === 'sentence') {
    words = words.map((word, i) => {
      if (i !== 0 && !isAcronym(word)) {
        return decapitalize(word);
      } else {
        return word;
      }
    });
  }

  return words.join(' ');
}

function isAcronym(word: string): boolean {
  if (word.length < 2) {
    return false;
  }

  return word.toUpperCase() === word;
}

function stripV1(str: string | undefined): string {
  return str?.replace(/^V1/, '') ?? '';
}

function isStringTrue(value: string | undefined): boolean {
  return String(value).toLowerCase() === 'true';
}

function tryParseJsonString(str: string): object | null {
  try {
    return JSON.parse(str);
  } catch {
    return null;
  }
}

export {
  splitCamelCase,
  splitCamelCaseIntoParts,
  splitSlug,
  splitSlugIntoParts,
  splitPeriod,
  splitPeriodIntoParts,
  decapitalize,
  humanizeName,
  stripV1,
  isStringTrue,
  titleifySnakeCaseString,
  tryParseJsonString,
};
