import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SliceError,
  SliceName,
  StoreStateNode,
} from '@squareup/dex-types-data-state';
import { Domain } from '@squareup/dex-types-shared-app-launch';
import {
  ChangelogEntryPage,
  ChangelogSetPage,
  DocPage,
  DocRedirect,
  NavSet,
  ReleaseTrain,
} from '@squareup/dex-types-shared-docs';

type PageViewState = StoreStateNode<{
  page?: DocPage | ChangelogEntryPage | ChangelogSetPage | DocRedirect | null;
  requirementsDomain?: Domain | null;
}>;

type NavSetState = StoreStateNode<{
  value?: NavSet | null;
}>;

type AdminFeatureState = {
  showMarkdown: boolean;
  isPreview: boolean;
  releaseTrain: {
    inView: boolean;
    state: StoreStateNode<ReleaseTrain>;
  };
  htmlHighlight: boolean;
};

type DocsShellViewState = {
  pageInView: PageViewState;
  navSet: NavSetState;
  adminFeature: AdminFeatureState;
};

type DocsPageInViewPayload = {
  page?: DocPage | ChangelogEntryPage | ChangelogSetPage | null;
  requirementsDomain?: Domain | null;
};

type DocsNavSetPayload = {
  value: NavSet | null;
};

const initialState: DocsShellViewState = {
  pageInView: { isLoading: true },
  navSet: { isLoading: true },
  adminFeature: {
    showMarkdown: false,
    isPreview: false,
    releaseTrain: { inView: false, state: { isLoading: true } },
    htmlHighlight: false,
  },
};

function isSliceError(data: unknown): data is SliceError {
  return Boolean((data as SliceError)?.status);
}

const shellViewStateSlice = createSlice({
  initialState,
  name: SliceName.docsShellViewState,
  reducers: {
    setPageInView(
      state: DocsShellViewState,
      { payload }: PayloadAction<DocsPageInViewPayload | SliceError>
    ) {
      const result = isSliceError(payload)
        ? {
            page: null,
            requirementsDomain: null,
            error: payload,
          }
        : {
            page: payload.page || null,
            requirementsDomain: payload.requirementsDomain || null,
          };

      return {
        ...state,
        pageInView: result,
      };
    },
    setNavSet(
      state: DocsShellViewState,
      { payload }: PayloadAction<DocsNavSetPayload | SliceError>
    ) {
      const result = isSliceError(payload)
        ? {
            navSet: null,
            error: payload,
          }
        : {
            value: payload?.value || null,
          };

      return {
        ...state,
        navSet: result,
      };
    },
    setIsPreview(
      state: DocsShellViewState,
      { payload }: PayloadAction<{ isPreview: boolean }>
    ) {
      if (payload.isPreview === state.adminFeature.isPreview) {
        return state;
      }

      return {
        ...state,
        adminFeature: {
          ...state.adminFeature,
          isPreview: payload.isPreview,
        },
      };
    },
    setShowMarkdown(
      state: DocsShellViewState,
      { payload }: PayloadAction<{ showMarkdown: boolean }>
    ) {
      return {
        ...state,
        adminFeature: {
          ...state.adminFeature,
          showMarkdown: payload.showMarkdown,
        },
      };
    },
    setReleaseTrain(
      state: DocsShellViewState,
      {
        payload,
      }: PayloadAction<
        { inView: boolean; state?: ReleaseTrain | null } | SliceError
      >
    ) {
      if (isSliceError(payload)) {
        return {
          ...state,
          adminFeature: {
            ...state.adminFeature,
            releaseTrain: {
              ...state.adminFeature.releaseTrain,
              state: { error: payload },
            },
          },
        };
      }

      return {
        ...state,
        adminFeature: {
          ...state.adminFeature,
          releaseTrain: {
            inView: payload.inView,
            state: payload.state || { isLoading: true },
          },
        },
      };
    },
    setHtmlHighlight(
      state: DocsShellViewState,
      { payload }: PayloadAction<{ highlight: boolean }>
    ) {
      return {
        ...state,
        adminFeature: {
          ...state.adminFeature,
          htmlHighlight: payload.highlight,
        },
      };
    },
  },
});

// Actions
const {
  setPageInView,
  setNavSet,
  setIsPreview,
  setShowMarkdown,
  setReleaseTrain,
  setHtmlHighlight,
} = shellViewStateSlice.actions;

// Store config
const docsShellViewStateStoreConfig = {
  reducer: shellViewStateSlice.reducer,
  reducerPath: shellViewStateSlice.name,
};

export {
  setPageInView,
  setNavSet,
  setIsPreview,
  setShowMarkdown,
  setReleaseTrain,
  setHtmlHighlight,
  docsShellViewStateStoreConfig,
  type DocsPageInViewPayload,
  type DocsNavSetPayload,
};
