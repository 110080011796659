import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { WithRequiredNotNullable } from '@squareup/dex-types-shared-utils';

/**
 * All possible errors resulting from sync state transitions.
 */
type SliceError =
  | {
      data: string;
      error: string;
      originalStatus: number;
      status: 'PARSING_ERROR';
    }
  | {
      data?: undefined | string;
      error: string;
      originalStatus?: number;
      status: 'CUSTOM_ERROR';
    };

/**
 * All possible errors resulting from async data api state transitions/calls.
 */
type ApiError =
  | FetchBaseQueryError
  | SerializedError
  /**
   * Catch all error returned by RTKQ.
   */
  | { error: string; status: string };

/**
 * Loading state values.
 */
type LoadingState<TData, TError> = {
  isLoading: true;
  error?: TError | undefined;
} & Partial<TData>;

/**
 * Error state values.
 */
type ErrorState<TData, TError> = {
  isLoading?: false | undefined;
  error: TError;
} & Partial<TData>;

/**
 * Data state values.
 */
type DataState<TData> = {
  isLoading?: false | undefined;
  error?: undefined;
} & TData;

type ErrorLoadingState<TData, TError> =
  | DataState<TData>
  | ErrorState<TData, TError>
  | LoadingState<TData, TError>;

/**
 * States resulting from store query execution.
 * Any given node of the state tree, if there is a
 * loading or error state this the contract a consumer can expect.
 */
type StoreStateNode<TData = unknown> = ErrorLoadingState<TData, SliceError>;

/**
 * States resulting from api execution.
 */
type ApiStoreStateNode<TData = unknown> = ErrorLoadingState<TData, ApiError>;

/**
 * The common contract for any slice loading state.
 */
type StateNode<TData = unknown> =
  | ApiStoreStateNode<TData>
  | StoreStateNode<TData>;

/**
 * The names used for slice definitions.
 * WARNING: Do not use the same name for multiple slices,
 * this will cause one slice to override the other in the store.
 */
enum SliceName {
  sharedShellViewState = 'sharedShellViewState',
  techRefShellViewState = 'techRefShellViewState',
  docsShellViewState = 'docsShellViewState',
  devsConsoleShellViewState = 'devsConsoleShellViewState',
}

/**
 * A state node that has a defined error.
 */
type StateNodeWithError<T> = WithRequiredNotNullable<StateNode<T>, 'error'>;

type StateNodeError = StateNodeWithError<unknown>['error'];

type NullableStateNodeError = StateNode<unknown>['error'];

// Think twice on the sub types exported here. We need to keep these as high level
// as possible as to not end up with maintainance issues and confusing types all over the application.
export {
  type StateNodeWithError,
  type NullableStateNodeError,
  type SliceError,
  type StateNode,
  type StoreStateNode,
  type StateNodeError,
  SliceName,
};

export { type SerializedError } from '@reduxjs/toolkit';
