enum DeveloperContactContentTypes {
  DEV_NEWSLETTER = 'dev_newsletter',
  APP_MARKETPLACE_REVIEWS = 'app_marketplace_reviews',
}

const DeveloperContentTypeStringKeys = {
  [DeveloperContactContentTypes.DEV_NEWSLETTER]: 'dev-newsletter',
  [DeveloperContactContentTypes.APP_MARKETPLACE_REVIEWS]:
    'app-marketplace-reviews',
};

interface DeveloperContact {
  uuid: string;
  email_address: string;
  content_types: DeveloperContactContentTypes[];
}

type DeveloperContactRequest = Omit<DeveloperContact, 'uuid'>;

interface DeveloperContactPayload {
  contacts: DeveloperContact[];
  app_marketplace_contacts: DeveloperContact[];
}

export {
  DeveloperContactContentTypes,
  DeveloperContentTypeStringKeys,
  type DeveloperContact,
  type DeveloperContactRequest,
  type DeveloperContactPayload,
};
