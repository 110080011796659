import { Api, Endpoint, Entity, Graph, Webhook } from '@square/ignition';

/**
 * Graph entity extractors.
 */

function isDefined<T>(
  value: NonNullable<T> | undefined | null
): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

function extractGraphApi(
  { api }: { api?: string | undefined },
  graph: Graph
): Api | undefined {
  return isDefined(api) ? graph.getApi(api.replace('-api', '')) : undefined;
}

/**
 * Graph entity extractors.
 */

function extractGraphObject(
  { object }: { object?: string | undefined },
  graph: Graph
): Entity | undefined {
  return isDefined(object) ? graph.getEntity(object) : undefined;
}

function extractGraphEnum(
  { enum: obj }: { enum?: string | undefined },
  graph: Graph
): Entity | undefined {
  return isDefined(obj) ? graph.getEntity(obj) : undefined;
}

function extractGraphEndpoint(
  { endpoint }: { endpoint?: string | undefined },
  graph: Graph
): Endpoint | undefined {
  return isDefined(endpoint) ? graph.getEndpoint(endpoint) : undefined;
}

function extractGraphWebhook(
  { webhook }: { webhook?: string | undefined },
  graph: Graph
): Webhook | undefined {
  return isDefined(webhook) ? graph.getWebhook(webhook) : undefined;
}

export {
  extractGraphApi,
  extractGraphObject,
  extractGraphEndpoint,
  extractGraphEnum,
  extractGraphWebhook,
};
