import {
  ThunkAction,
  Selector,
  AnyAction,
  EnhancedStore,
} from '@reduxjs/toolkit';
import { ThunkMiddlewareFor } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { getDeveloperApiStoreConfig } from '@squareup/dex-data-shared-developer-api';
import {
  graphEntitiesInViewStoreConfig,
  techRefShellViewStateStoreConfig,
} from '@squareup/dex-tech-data-slices';
import { combineReducers } from 'redux';

const developerApiStoreConfig = getDeveloperApiStoreConfig();
const reducers = {
  [techRefShellViewStateStoreConfig.reducerPath]:
    techRefShellViewStateStoreConfig.reducer,
  [developerApiStoreConfig.reducerPath]: developerApiStoreConfig.reducer,
  [graphEntitiesInViewStoreConfig.reducerPath]:
    graphEntitiesInViewStoreConfig.reducer,
};

const combinedReducers = combineReducers(reducers);
const middleware = developerApiStoreConfig.middleware;

type TechRefStoreState = ReturnType<typeof combinedReducers>;
type Middlewares = [ThunkMiddlewareFor<TechRefStoreState>];
type TechRefStore = EnhancedStore<TechRefStoreState, AnyAction, Middlewares>;
type TechRefStoreDispatch = TechRefStore['dispatch'];
type TechRefStoreSelector<T> = Selector<TechRefStoreState, T>;

type TechRefStoreAction<T> = ThunkAction<
  T,
  TechRefStoreState,
  undefined /* Extra args undefined for now*/,
  AnyAction
>;

type TechRefStoreAsyncAction<T> = ThunkAction<
  Promise<T>,
  TechRefStoreState,
  undefined /* Extra args undefined for now*/,
  AnyAction
>;

export {
  reducers,
  middleware,
  type TechRefStoreSelector,
  type TechRefStore,
  type TechRefStoreDispatch,
  type TechRefStoreAction,
  type TechRefStoreAsyncAction,
  type TechRefStoreState,
};
