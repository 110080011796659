import { type PermissionSetDomainAction } from '@squareup/dex-types-shared-rbac-permissions';

import { getCanonicalRoleTokens } from './get-canonical-role-tokens';
import { getRolesForDomainAction } from './get-roles-for-domain-action';

const getRoleTokensForDomainAction = (action: PermissionSetDomainAction) => {
  const roleNames = getRolesForDomainAction(action);

  const canonicalRoleTokens = getCanonicalRoleTokens();

  return roleNames
    .map((role) => canonicalRoleTokens.get(role))
    .filter((token): token is string => token !== undefined);
};

export { getRoleTokensForDomainAction };
