import {
  BaseEndpointBuilder,
  endpointBuilder,
} from '@squareup/dex-data-shared-base-api';
import {
  UserInfo,
  UserPermissions,
} from '@squareup/dex-types-shared-developer-data-api';
import {
  GetPreferenceScopeInput,
  GetPreferenceScopePayload,
  PreferenceGroup,
  PreferenceScope,
} from '@squareup/dex-types-shared-developer-preferences';
import { HTTP_METHOD } from '@squareup/dex-types-shared-http';
import {
  CheckRbacPermissionsInput,
  CheckRbacPermissionsPayload,
} from '@squareup/dex-types-shared-rbac-permissions';
import {
  SmileySurveyResponseSubmissionInput,
  SmileySurveySettingsPayload,
} from '@squareup/dex-types-shared-smiley-survey';
import { getRoleTokensForDomainAction } from '@squareup/dex-utils-shared-rbac-permissions';

import { DEVELOPER_API_REDUCER_PATH } from './developer-api-constants';
import { DeveloperApiTagTypes } from './developer-api-tag-types';

const DEVELOPER_DATA_API_URL = '/api/developer-data';
const appName = 'developer-data';

const developerDataEndpointFactory = (
  builder: BaseEndpointBuilder<typeof DEVELOPER_API_REDUCER_PATH>
) => ({
  /**
   * Smiley survey endpoints
   */
  dismissSmileySurvey: builder.mutation<undefined, undefined>({
    query: () => ({
      path: `${DEVELOPER_DATA_API_URL}/smiley-survey-dismissals`,
      appName,
      method: HTTP_METHOD.POST,
    }),
  }),

  getSmileySurveySettings: builder.query<
    SmileySurveySettingsPayload,
    undefined
  >({
    query: () => ({
      path: `${DEVELOPER_DATA_API_URL}/smiley-survey-settings`,
      appName,
    }),
    providesTags: [DeveloperApiTagTypes.SmileySurveySettings],
  }),

  submitSmileySurveyResponse: builder.mutation<
    SmileySurveySettingsPayload,
    SmileySurveyResponseSubmissionInput
  >({
    query: ({ comment, score }) => ({
      path: `${DEVELOPER_DATA_API_URL}/smiley-survey-responses`,
      appName,
      method: HTTP_METHOD.POST,
      additionalHeaders: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ comment, score }),
    }),
  }),

  /**
   * Developer Preferences endpoints
   */
  getPreferenceScope: builder.query<
    GetPreferenceScopePayload,
    GetPreferenceScopeInput
  >({
    query: ({
      code,
      include = [],
      instance_token = undefined,
    }: GetPreferenceScopeInput) => {
      let path = `${DEVELOPER_DATA_API_URL}/preference-scopes?code=${code}&include=${include.join(
        '.'
      )}`;

      if (instance_token) {
        path += `&instance_token=${instance_token}`;
      }

      return {
        path,
        appName,
      };
    },
    providesTags: [DeveloperApiTagTypes.PreferenceScope],
  }),

  createPreferenceScope: builder.mutation<
    { data: { id: string } },
    {
      preferenceScope: PreferenceScope & {
        preference_groups?: PreferenceGroup[];
      };
    }
  >({
    query: ({ preferenceScope }) => ({
      path: `${DEVELOPER_DATA_API_URL}/preference-scopes`,
      appName,
      method: HTTP_METHOD.POST,
      body: JSON.stringify({
        preference_scope: preferenceScope,
      }),
      additionalHeaders: {
        'Content-Type': 'application/json',
      },
    }),
  }),

  createPreferenceGroup: builder.mutation<
    unknown,
    { preferenceGroup: PreferenceGroup; scopeId: string }
  >({
    query: ({ preferenceGroup, scopeId }) => ({
      path: `${DEVELOPER_DATA_API_URL}/preference-groups`,
      appName,
      method: HTTP_METHOD.POST,
      body: JSON.stringify({
        preference_group: { ...preferenceGroup, scope_id: scopeId },
      }),
      additionalHeaders: {
        'Content-Type': 'application/json',
      },
    }),
  }),

  updatePreferenceGroup: builder.mutation<unknown, unknown>({
    query: ({ groupId, scopeId, preferenceGroup }) => ({
      path: `${DEVELOPER_DATA_API_URL}/preference-groups/${groupId}`,
      appName,
      method: HTTP_METHOD.PATCH,
      body: JSON.stringify({
        group_id: groupId,
        preference_group: {
          ...preferenceGroup,
          scope_id: scopeId,
          id: groupId,
        },
      }),
    }),
  }),

  getUserInfo: builder.query<UserInfo, void>({
    query: () => ({
      path: `${DEVELOPER_DATA_API_URL}/me`,
      appName,
    }),
  }),

  checkRbacPermissions: builder.mutation<
    CheckRbacPermissionsPayload,
    CheckRbacPermissionsInput
  >({
    query: ({ action, ...rest }) => {
      const roleTokens = getRoleTokensForDomainAction(action);

      return {
        path: `${DEVELOPER_DATA_API_URL}/permissions/check`,
        appName,
        method: HTTP_METHOD.POST,
        body: JSON.stringify({
          ...rest,
          role_tokens: roleTokens,
        }),
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
      };
    },
  }),

  getUserRbacPermissions: builder.query<UserPermissions, void>({
    query: () => ({
      path: `${DEVELOPER_DATA_API_URL}/permissions/me`,
      appName,
    }),
    providesTags: [DeveloperApiTagTypes.RbacPermissions],
  }),
});

const developerDataEndpoints = developerDataEndpointFactory(endpointBuilder);

export { developerDataEndpoints, developerDataEndpointFactory };
